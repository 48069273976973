import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";
import Player from '../../video/video';

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import Synoptis_01_A1 from '../../../../assets/case-study/synoptis/01/01A.png';
import Synoptis_01_B1 from '../../../../assets/case-study/synoptis/01/01B.png';
import Synoptis_01_C1 from '../../../../assets/case-study/synoptis/01/01C.png';

import Synoptis_02_A1 from '../../../../assets/case-study/synoptis/02/02A.png';
import Synoptis_02_B1 from '../../../../assets/case-study/synoptis/02/02B.png';
import Synoptis_02_C1 from '../../../../assets/case-study/synoptis/02/02C.png';

import Synoptis_03_A1 from '../../../../assets/case-study/synoptis/03/03A.png';
import Synoptis_03_B1 from '../../../../assets/case-study/synoptis/03/03B.png';
import Synoptis_03_C1 from '../../../../assets/case-study/synoptis/03/03C.png';

import Synoptis_04_A1 from '../../../../assets/case-study/synoptis/04/04A.png';
import Synoptis_04_B1 from '../../../../assets/case-study/synoptis/04/04B.png';
import Synoptis_04_C1 from '../../../../assets/case-study/synoptis/04/04C.png';

import Synoptis_05_A1 from '../../../../assets/case-study/synoptis/05/05A.png';
import Synoptis_05_B1 from '../../../../assets/case-study/synoptis/05/05B.png';
import Synoptis_05_C1 from '../../../../assets/case-study/synoptis/05/05C.png';

import Synoptis_06_A1 from '../../../../assets/case-study/synoptis/06/06A.png';
import Synoptis_06_B1 from '../../../../assets/case-study/synoptis/06/06B.png';
import Synoptis_06_C1 from '../../../../assets/case-study/synoptis/06/06C.png';

import Synoptis_07_A1 from '../../../../assets/case-study/synoptis/07/07A.png';
import Synoptis_07_B1 from '../../../../assets/case-study/synoptis/07/07B.png';
import Synoptis_07_C1 from '../../../../assets/case-study/synoptis/07/07C.png';

import Synoptis_08_A1 from '../../../../assets/case-study/synoptis/08/08A.png';
import Synoptis_08_B1 from '../../../../assets/case-study/synoptis/08/08B.png';
import Synoptis_08_C1 from '../../../../assets/case-study/synoptis/08/08C.png';

import Synoptis_09_A1 from '../../../../assets/case-study/synoptis/09/09A.png';
import Synoptis_09_B1 from '../../../../assets/case-study/synoptis/09/09B.png';
import Synoptis_09_C1 from '../../../../assets/case-study/synoptis/09/09C.png';

import Synoptis_10_A1 from '../../../../assets/case-study/synoptis/10/10A.png';
import Synoptis_10_B1 from '../../../../assets/case-study/synoptis/10/10B.png';
import Synoptis_10_C1 from '../../../../assets/case-study/synoptis/10/10C.png';

import Synoptis_11_A1 from '../../../../assets/case-study/synoptis/11/11A.png';

import Synoptis_12_A1 from '../../../../assets/case-study/synoptis/12/12A.png';
import Synoptis_12_B1 from '../../../../assets/case-study/synoptis/12/12B.png';
import Synoptis_12_C1 from '../../../../assets/case-study/synoptis/12/12C.png';

import Synoptis_13_A1 from '../../../../assets/case-study/synoptis/13/13A.png';

import Synoptis_14_A1 from '../../../../assets/case-study/synoptis/14/14A.png';
import Synoptis_14_B1 from '../../../../assets/case-study/synoptis/14/14B.png';

import Synoptis_15_A1 from '../../../../assets/case-study/synoptis/15/15A.png';
import Synoptis_15_B1 from '../../../../assets/case-study/synoptis/15/15B.png';
import Synoptis_15_C1 from '../../../../assets/case-study/synoptis/15/15C.png';

import { NutriciaGlobal } from './synoptis.styled';
import SynoptisLogo from '../../carousel/logo/synoptis';
import SEO from '../../../seo/seo';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.synoptis.textColor;
  const colorBg = Routes.portfolio.childs.synoptis.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 5) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO
        title={Routes.portfolio.childs.synoptis.name}
        description={Routes.portfolio.childs.synoptis.description}
        keywords={Routes.portfolio.childs.synoptis.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <NutriciaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'kampania-woda-morska',
            name: 'kampania Woda Morska',
          },
          {
            slug: 'kampania-witamina-d',
            name: 'kampania Witamina D',
          },
          {
            slug: 'kampania-erosmowy',
            name: 'kampania Erosmowy',
          },
          {
            slug: 'kampania-altaminium',
            name: 'kampania Altaminium',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge synoptis__intro"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <SynoptisLogo />
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            wszystko dobrze z Synoptis Pharma! nasza współpraca z Synoptis Pharma zaczęła się od jednego projektu,
a skończyliśmy z 4 wygranymi przetargami i mamy apetyt na więcej! jednak wygrać przetarg to jedno, a dobrze zrealizować projekt,
to drugie. a tu w obu przypadkach wszystko dobrze. zresztą sami zobaczcie.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  wszystko dobrze<br />z Synoptis Pharma!
                </Title>
                <Text>
                nasza współpraca z Synoptis Pharma zaczęła się od jednego projektu,
a skończyliśmy z 4 wygranymi przetargami i mamy apetyt na więcej! jednak wygrać przetarg to jedno, a dobrze zrealizować projekt,
to drugie. a tu w obu przypadkach wszystko dobrze. zresztą sami zobaczcie.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts synoptis__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2020}
                  max={new Date().getFullYear() - 2000}
                  undertext="lata"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2023-07-15', 194, 0.5)}
                  max={getProjects('2023-07-15', 194, 0.5)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={320}
                  max={420}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-woda-morska"
          id="kampania-woda-morska"
          className="nutricia-section__pr"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              Woda morska, czyli „gwiezdny poziom mądrego marketingu” 
            </SubTitle>
            <Text>
              gwiezdny poziom? oj Michał, ale poleciałeś z tym copy… naprawdę… tylko to wcale nie Michał, a użytkowniczka Facebooka, której wyjątkowo spodobało się nasze podejście do prowadzenia profilu Wody morskiej.<br/><br />
            </Text>
            <Text>
              z tym projektem naprawdę popłynęliśmy! „Mi to rybka!” to kampania dla Wody morskiej, dzięki której złowiliśmy już brązową statuetkę w konkursie IAB Mixx Awards, wyróżnienie Golden Arrow i nagrodę za projekt roku w wewnętrznym plebiscycie Synoptis Pharma. zobacz, co na to wpłynęło.
            </Text>
          </TextWrap>
          <ImgSection
            baseImg={Synoptis_15_A1}
            secondImg={Synoptis_15_B1}
            thirdImg={Synoptis_15_C1}
          />
          <TextWrap>
            <Text>
              bo wiecie, w reklamie w mediach społecznościowych nie chodzi o pokazywanie produktu i wychwalanie jego zalet, a o społeczność właśnie. stąd taka nazwa. media społecznościowe. serio. no i my to tak robimy... wspieramy mamy w codziennych trudach, edukujemy je wspólnie z rewelacyjnymi ekspertkami i rozmawiamy z nimi o ich problemach
            </Text>
          </TextWrap>
          <div className="synoptis__section2_image_1">
            <ImgSection
              baseImg={Synoptis_05_A1}
              secondImg={Synoptis_05_C1}
              thirdImg={Synoptis_05_B1}
            />
          </div>
          <TextWrap>
            <Text>
              poza treściami dla matek przygotowaliśmy szereg kreatywnych zabaw dla dzieci m.in. akwarium z kartonu, milusią meduzę z mackami z włóczki czy łowienie rybek klamerką.
            </Text>
          </TextWrap>
          <div className="synoptis__section2_image_2">
            <ImgSection
              baseImg={Synoptis_06_C1}
              secondImg={Synoptis_06_A1}
              thirdImg={Synoptis_06_B1}
            />
          </div>
          <TextWrap>
            <Text>
              jakie są najlepsze nagrody w konkursie? takie, które można samemu zaprojektować! mali artyści przygotowali projekt morskich pluszaków, a do autorów najlepszych zgłoszeń dopłynęły kurierem maskotki.
            </Text>
          </TextWrap>
          <div className="synoptis__section2_image_3">
            <ImgSection
              baseImg={Synoptis_07_C1}
              secondImg={Synoptis_07_A1}
              thirdImg={Synoptis_07_B1}
            />
          </div>
          <TextWrap>
            <Text>
              stworzyliśmy też landing page, na którym znajdziecie informacje
              o produktach, materiały dla najmłodszych i artykuły dotyczące odpowiedniej higieny nosa oraz skutecznych metod na radzenie sobie
              z katarem.
            </Text>
          </TextWrap>
          <div>
            <ImgSection
              baseImg={Synoptis_08_B1}
              secondImg={Synoptis_08_A1}
              thirdImg={Synoptis_08_C1}
            />
          </div>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-witamina-d"
          id="kampania-witamina-d"
          className="animated fadeInUp faster nutricia-section__kampania"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle className="text-right">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              do dzieła drużyno De!
            </SubTitle>
            <Text className="text-right">
            Debora, Dezydery, Delfina, Demetriusz, Demi, Denis i Dekster – poznajcie rodzinę De! De facto, jej perypetie odpowiadają na codzienne wyzwania wielu rodzin, związane z zapotrzebowaniem na witaminę D.  
            </Text>
            <Player
              video={{
                src: 'https://player.vimeo.com/video/833224920?h=3b41ae7e9f&amp',
              }}
            />
            <Text className="text-right">
            Demonstrujemy przygotowany przez nas landing page, na którym poznacie bohaterów rodziny De, znajdziecie odpowiedzi na najczęściej zadawane pytania, zapoznacie się z produktem i pobierzecie materiały dla dzieci.
            </Text>
          </TextWrap>
          <div className="synoptis__section1_image_1">
            <ImgSection
              baseImg={Synoptis_01_B1}
              secondImg={Synoptis_01_A1}
              thirdImg={Synoptis_01_C1}
            />
          </div>
          <TextWrap>
            <Text className="text-right">
              Dedykujemy ich fanpage na Facebooku i Instagramie wszystkim tym, którzy interesują się zdrowym stylem życia i lubią ciekawe historie. Delikatnie zachęcamy do odwiedzin!
            </Text>
          </TextWrap>
          <div>
            <ImgSection
              baseImg={Synoptis_02_A1}
              secondImg={Synoptis_02_B1}
              thirdImg={Synoptis_02_C1}
            />
          </div>
          <TextWrap>
            <Text className="text-right">
              Definitywnie warto też przyjrzeć się gadżetom, które dla nich wyprodukowaliśmy.
            </Text>
          </TextWrap>
          <div className="synoptis__section1_image_2">
            <ImgSection
              baseImg={Synoptis_03_C1}
              secondImg={Synoptis_03_A1}
              thirdImg={Synoptis_03_B1}
            />
          </div>
          <div>
            <ImgSection
              baseImg={Synoptis_04_C1}
              secondImg={Synoptis_04_A1}
              thirdImg={Synoptis_04_B1}
            />
          </div>
          
        </SectionProject>
      </Observer>
      
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-erosmowy"
          id="kampania-erosmowy"
          className="synoptis__section3"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              przychodzi mężczyzna i ścisza głos... 
            </SubTitle>
            <Text className="text-right">
              żarciki z seksu i twardziele mówiący, jak ma postępować „prawdziwy mężczyzna”? tak wszyscy kojarzymy reklamy środków na erekcję... my uważamy jednak, że poważny problem zasługuje na poważne potraktowanie. dlatego stawiamy na edukację.<br/><br/>
            </Text>
            <Text className="text-right">
              z przeprowadzonych na potrzeby kampanii rozmów z farmaceutami wynika, że problemy z potencją nie są dla nich tabu. jednak większość przyznała, że mimo wieloletniego doświadczenia często nie wie, jak zacząć rozmowę na ten temat. z tego insightu narodziły się Erosmowy. to pierwsza interdyscyplinarna kampania edukacyjna o zaburzeniach potencji i erekcji, skierowana do farmaceutów i farmaceutek, w której swoją wiedzą dzielą się m.in. kardiolog, seksuolog, androlog, urolog, diabetolożka, psycholożka, psychiatrka i ich koleżanki po fachu.<br/><br/>
            </Text>
            <Text className="text-right">
              rozmowy o realnych problemach są dostępne na stronie https://erosmowy.pl/ i na kanale Erosmowy na Spotify.
            </Text>
          </TextWrap>
          <div className="synoptis__section3_image_1">
            <ImgSection
              baseImg={Synoptis_09_B1}
              secondImg={Synoptis_09_A1}
              thirdImg={Synoptis_09_C1}
            />
          </div>
          <TextWrap>
            <Text className="text-right">
              działania reklamowe są realizowane, m.in. poprzez: artykuły sponsorowane i kampanię display na stronach skierowanych do farmaceutów, mailingi, print, posty w grupach zamkniętych, a także na kanałach farmaceutów-influencerów. debata jest również wykorzystywana jako szkolenie na stronie https://www.aptekarska.pl/.
            </Text>
          </TextWrap>
          <div className="synoptis__section3_image_2">
            <ImgSection
              baseImg={Synoptis_10_B1}
              secondImg={Synoptis_10_A1}
              thirdImg={Synoptis_10_C1}
            />
          </div>
          <div className="synoptis__section3_image_3">
            <ImgSection
              baseImg={Synoptis_11_A1}
            />
          </div>
          <TextWrap>
            <Text className="text-right">
             tytuł pierwszej interdyscyplinarnej kampanii o zaburzeniach potencji i erekcji zobowiązuje do tego, by
             opowiedzieć o niej nie tylko słowem, ale i obrazem. takim dźwiękiem  i efektowynymi przejściami. sami zobaczcie.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/827553083?h=d3a483b473&amp',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-altaminium"
          id="kampania-altaminium"
          className="nutricia-section__pos"
        >
        <div
          className="nutricia-section__print"
        >

        
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              Altaminium pomaga w urazie. i w Urazie też.
            </SubTitle>
            <Text>
              czy przeznaczenie istnieje? nie konsultujemy naszych działań z astrologami, nie dyskryminujemy też Skorpionów na rozmowach kwalifikacyjnych, ale... w przypadku tego projektu coś było na rzeczy.<br /><br />
            </Text>
            <Text>
              nasz klient Synoptis Pharma zasugerował, by komunikację APTEO Altaminium – żelu na urazy, obrzęki i skręcenia – skierować do sportowców-amatorów, jako najbardziej narażonych na kontuzje. wyraził też zainteresowanie sponsoringiem wydarzenia.<br /><br /> 
            </Text>
            <Text>
              no i znaleźliśmy wydarzenie, które nie tylko odbywa się w idealnym momencie dla rozpoczęcia kampanii, nie tylko jest w zasięgu budżetu, nie tylko w kontuzjogennej dyscyplinie... a był to duathlon w miejscowości Uraz pod Wrocławiem.<br /><br />
            </Text>
            <Text>
              wtedy poszło... żel na urazy i Uraz... co by tutaj... aha! tak powstało hasło nie tylko wykorzystujące ten zbieg okoliczności, ale też odnoszące się do produktu: Altaminium pomaga w Urazie.
            </Text>
          </TextWrap>
        </div>
        <Player
            video={{
              src: 'https://player.vimeo.com/video/757142799?h=c04ee5280c',
            }}
        />
        <TextWrap>
          <Text>
            Uraz stał się pretekstem komunikacyjnym i kopalnią kontentu do rozpoczęcia komunikacji w social mediach. dodatkowo rozpoczęliśmy współpracę z małżeństwem fizjotrenerów którzy, przygotowują treści lifestyle odpowiadające na zagadnienia #życiebezurazu i #treningbezurazu. 
          </Text>
        </TextWrap>
        <ImgSection
          baseImg={Synoptis_12_B1}
          secondImg={Synoptis_12_A1}
          thirdImg={Synoptis_12_C1}
        />
        <TextWrap>
          <Text>
            co było potem? relacja w social mediach, łopoczące flagi, pop-up store, sampling w pakietach startowych i spiker, który dziesiątki razy powtórzył: „APTEO Altaminium pomaga w Urazie!” 
          </Text>
        </TextWrap>
        <div className="synoptis__section4_image_1">
          <ImgSection
            baseImg={Synoptis_13_A1}
          />
        </div>
        <TextWrap>
          <Text>
          z rozbiegu przygotowaliśmy też materiały offline. efekty można było zobaczyć w prasie i na POSach w aptekach.
          </Text>
        </TextWrap>
        <div className="synoptis__section4_image_2">
          <ImgSection
            baseImg={Synoptis_14_A1}
            secondImg={Synoptis_14_B1}
          />
        </div>
        </SectionProject>
      </Observer>
      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={ Routes.portfolio.childs.nutricia.slug }>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';


import { Half } from '../../components/grid/half/Half.styled';
import { Container } from '../../components/grid/container/Container.styled';
import MainTitle from '../../components/ui/title/title';
import MenuIcon from '../../components/menu-icon/menu-icon';
import Routes from '../../routes/routes';
import { RodoText } from '../../components/ui/rodo-text/rodo-text.styled'
import { RodoContact, RodoContactHalf, RodoContactFull } from '../../components/ui/rodo-contact/rodo-contact.styled';
import { BG } from '../../components/grid/bg/bg.style';
import { MIN_ANIMATION } from '../../config/config';
import SEO from '../../components/seo/seo';

const mapStateToProps = state => {
  return {rodo: state.rodo};
};

const PolitykaPrywatnosci = ( {rodo} ) => {
  return (
      
    <BG 
      color="#8D91AB"
    >
      <SEO
        title="​​Praca w agencji reklamy - Pomarańcza"
        description="Poszukujemy do pracy w Pomarańcza. Sprawdź naszą ofertę i realizacje. Pomarańcza to ​✅​ 20 lat doświadczenia ​✅​ Spójne działania online i offline."
      />
      <MenuIcon 
        color="#8D91AB"
      />
      <div
        className="animated fadeInLeft faster" 
        style={{
          animationDelay: `${MIN_ANIMATION + 0.5}s`,
          zIndex: 4,
          position: 'fixed',
        }}
      >
        <Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--dark" exact to={Routes.kontakt.slug}>kontakt</Link>
      </div>
      <MainTitle 
        absolute={true}
        lessLetterSpace
        left='d'
        right='łącz&nbsp;do&nbsp;nas'
      />
      <Container>
        <Half>
          <RodoText
            className="carousel__item animated fadeInUp faster"
            style={{
              animationDelay: `${MIN_ANIMATION + 0.4}s`
            }}
          >
            <h2>Dzień dobry,</h2>
            <br/>
            <p>Administratorem twoich danych osobowych podanych w zgłoszeniu rekrutacyjnym jest POMARAŃCZA Sp. z o.o. z siedzibą przy ulicy Przelot 17, 60-408 Poznań, KRS 0000766064, NIP 7811987297, REGON 382310629, kapitał zakładowy 5.000 zł, <a href="mailto:ADO@pomarancza.pl">ADO@pomarancza.pl</a></p>
            <p>W każdym czasie możesz cofnąć zgodę, kontaktując się z nami pod wyżej wskazanym adresem.</p>
            <br/>
            <p>Twoje dane osobowe wskazane w Kodeksie pracy lub w innych ustawach szczegółowych przetwarzamy w oparciu o przepisy prawa i ich podanie jest konieczne do wzięcia udziału w rekrutacji. Pozostałe dane osobowe (np. wizerunek) przetwarzamy na podstawie Twojej dobrowolnej zgody, którą wyraziłaś/eś wysyłając nam swoje zgłoszenie rekrutacyjne i ich podanie nie ma wpływu na możliwość udziału w rekrutacji.</p>
            <p>Możemy przetwarzać Twoje dane osobowe zawarte w zgłoszeniu rekrutacyjnym także w celu ustalenia, dochodzenia lub obrony przed roszczeniami, jeżeli roszczenia dotyczą prowadzonej przez nas rekrutacji. W tym celu będziemy przetwarzać Twoje dane osobowe w oparciu o nasz prawnie uzasadniony interes, polegający na ustaleniu, dochodzeniu lub obrony przed roszczeniami w postępowaniu przed sądami lub organami państwowymi.</p>
            <p>Masz prawo dostępu do swoich danych, w tym uzyskania ich kopii, sprostowania danych, żądania ich usunięcia, ograniczenia przetwarzania, wniesienia sprzeciwu wobec przetwarzania oraz przeniesienia podanych danych (na których przetwarzanie wyraziłeś zgodę) do innego administratora danych. Masz także prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych. Cofnięcie zgody pozostaje bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</p>
            <p>Twoje dane osobowe przetwarzamy w celu prowadzenia rekrutacji na stanowisko wskazane w ogłoszeniu przez okres <strong>3 miesięcy</strong>, a gdy wyraziłaś/eś zgodę na udział w przyszłych rekrutacjach przez okres <strong>12 miesięcy</strong>. Ponadto Twoje dane osobowe możemy przechowywać dla celów ustalenia, dochodzenia lub obrony przed roszczeniami związanymi z procesem rekrutacji przez okres <strong>14 miesięcy</strong>.
Twoje dane osobowe możemy przekazać, dostawcom usług IT takich jak hosting oraz dostawcom systemów informatycznych.</p>
            <br/>
            <p>Nie przekazujemy Twoich danych osobowych poza Europejski Obszar Gospodarczy.</p>
            <br/>
            <p>W razie pytań możesz się z nami skontaktować pod adresem ul. Przelot 17, 60-408 Poznań lub naszym Administratorem Danych Osobowych Michałem Michalikiem- Prezesem Zarządu pod adresem ul. Przelot 17, 60-408 Poznań, <a href="mailto:ADO@pomarancza.pl">ADO@pomarancza.pl</a></p>
            <br/>
            <p>Z pozdrowieniami,</p>
            <p>Zespół Pomarańczy</p>
            {/* { ReactHtmlParser(rodo.content) } */}
          </RodoText>
        </Half>
        <Half mobileInitial={true} fullHeight={false}>
          <RodoContact>
            <RodoContactHalf
              className="carousel__item animated fadeInUp faster"
              style={{
                animationDelay: `${MIN_ANIMATION + 0.1}s`
              }}
            >
              { ReactHtmlParser(rodo.admin) }
            </RodoContactHalf>
            <RodoContactHalf
              className="carousel__item animated fadeInUp faster"
              style={{
                animationDelay: `${MIN_ANIMATION + 0.2}s`
              }}
            >
              { ReactHtmlParser(rodo.contact) }
            </RodoContactHalf>
            <RodoContactFull
              className="carousel__item animated fadeInUp faster"
              style={{
                animationDelay: `${MIN_ANIMATION + 0.3}s`
              }}
            >
              { ReactHtmlParser(rodo.info) }
            </RodoContactFull>
          </RodoContact>
        </Half>
      </Container>
    </BG>
  )
}

export default connect(mapStateToProps, null)(PolitykaPrywatnosci);
import React from 'react';

function Tesa () {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="277.077" height="76.351" viewBox="0 0 277.077 76.351">
      <g id="Group_141" data-name="Group 141" transform="translate(0 0)">
        <g id="Group_140" data-name="Group 140" transform="translate(0 0)">
          <path id="Path_134" data-name="Path 134" d="M218.255,0h15.593V76.352H155.329C192.111,56.477,218.255,0,218.255,0" transform="translate(43.229 -0.001)"  fill-rule="evenodd"/>
          <path id="Path_135" data-name="Path 135" d="M58.2,29.362H78.437s2.7-8.829-8.255-8.653A12.147,12.147,0,0,0,58.2,29.362" transform="translate(16.197 5.762)"  fill-rule="evenodd"/>
          <path id="Path_136" data-name="Path 136" d="M141.988,34.221c.079.077-17.779-2.231-17.218,9.044,2.932,7.066,17.137,1.589,17.218-9.044" transform="translate(34.721 9.483)"  fill-rule="evenodd"/>
          <path id="Path_137" data-name="Path 137" d="M159.872,15.086a4.093,4.093,0,0,0-4.062,4.36,4.061,4.061,0,1,0,8.1,0,4.088,4.088,0,0,0-4.037-4.36m1.373,7.381-1.515-2.5h-.9v2.5h-.961V16.623h2.222c1.406,0,2.131.493,2.131,1.678a1.5,1.5,0,0,1-1.53,1.6l1.638,2.571Z" transform="translate(43.363 4.198)"  fill-rule="evenodd"/>
          <path id="Path_138" data-name="Path 138" d="M160.6,17.722c0-.708-.692-.8-1.269-.8h-1.158v1.71h1.07c.724,0,1.358-.068,1.358-.915" transform="translate(44.02 4.71)"  fill-rule="evenodd"/>
          <path id="Path_139" data-name="Path 139" d="M259.644,0H0V76.351H190.381c19.313,0,25.514-34.683,22-40.715C245.405,32.978,259.644,0,259.644,0M41.459,65.052c-8.652-.229-15.24-5.718-14.45-13.808.876-6.507,5.316-23.343,5.316-23.343H14.709l5.319-9.451H34.392l2.384-9.677h11.59L46.3,18.534l20.636.005-5.558,9.52H43.919L39.552,45.841s-2.385,6.514,2.3,8.179C50.827,56.957,58.6,50.445,58.6,50.445l1.984,9.291a28.891,28.891,0,0,1-19.125,5.316M80.829,54.977c9.442.631,10.868-5.956,10.949-5.956h11.906S100.6,66.083,78.764,64.971c-10.8.01-20.324-8.407-17.623-24.049,0,0,1.667-24.292,27.384-23.5,12.146.867,17.218,7.064,18.1,14.606.633,5.8-1.512,12.706-1.512,12.706H72.653s-2.546,9.283,8.176,10.241m47.865,10.241c-17.549.64-19.746-4.158-20.168-4.3-4.961-4.743-2.141-11.9-2.141-11.9H117.97S115.909,56.45,125.756,56c8.8-.395,6.346-5.956,6.266-5.867-.793-3.973-14.92-4.928-19.924-14.376-3.965-13.177,10.953-17.469,11.592-17.381,13.884-3.1,21.669,2.14,21.669,2.14,6.351,3.33,5.163,10.8,5.163,10.8H139.006s.878-5.158-7.222-5.242c-8.331.084-6.826,5.08-6.826,5.08,1.034,3.814,13.729,6.353,17.143,10.725,9.1,11.942-1.993,22.829-13.407,23.339m62.225-32.952s-5.87,25.088-5.637,25.413c-.732,1.87,0,6.507,0,6.507H174.406l-.472-3.095s-4.846,3.891-13.574,4.128c0,0-10.634.153-12.938-9.609,0,0-1.745-9.286,7.148-16.355,6.977-5.478,24.046-3.809,24.046-3.809s4.929-9.287-3.807-9.052c-6.911-.4-7.864,5-7.864,5H154.407c2.7-13.011,16.04-13.491,16.04-13.491s7.608-1.508,14.44,2.144c9.052,4.051,6.032,12.224,6.032,12.224m12.316-3.338a5.087,5.087,0,0,1-5.2-5.283,5.184,5.184,0,1,1,10.366,0,5.117,5.117,0,0,1-5.167,5.283" transform="translate(0 -0.001)"  fill-rule="evenodd"/>
        </g>
      </g>
    </svg>
  )
}

export default Tesa;
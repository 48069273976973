import React, { useEffect, useState} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'

import { Container } from '../../components/grid/container/Container.styled';
import { Half } from '../../components/grid/half/Half.styled';
import { Link } from "react-router-dom";
import MenuIcon from '../../components/menu-icon/menu-icon';
import { TitleWrap, TitleCenter } from '../../components/ui/title/title.styled';
import { HomeIntroTitle, HomeIntroPlus } from './../../components/home-intro/home-intro.styled';
import { GoldenArrowTitleDescription, GoldenArrowTitle, GoldenArrowImageWrapper, GoldenArrowImage, GoldenArrowDescription, ArticleImg, ArticleText, ArticleTitle, ArticleTitleWrap, ArticleTitleTitle, ArticleTitleDescription, ArticleTitleDonuts, ArticleBottomContainer, ArticleTop, GoldenArrow, GoldenArrowWrapper } from './article.styled';
import { TextWrap } from '../../components/portfolio/clients/nivea/nivea.styled';
import DonutChart from '../../components/ui/donat-chart/donat-chart';
import { BG } from '../../components/grid/bg/bg.style';
import { ShowDesktop } from '../../components/grid/showHide/showHide.style';
import { BackMobile } from '../../components/ui/back-mobile/back-mobile.styled';
import { Next } from '../../components/ui/next/next.styled';
import { More } from '../../components/ui/more/more.styled';
import Hrefs from '../../components/hrefs/hrefs';
import NotFound from '../not-found/not-found';
import SEO from '../../components/seo/seo';
import Player from '../../components/portfolio/video/video';

import GoldenArrowImg from '../../assets/golden-arrow/arrow.png';

const mapStateToProps = state => {
  return {articles: state.articles};
};

const Article = ({ match, articles, history }) => {
  const [nextArticle, updateNextArticle] = useState(false)
  const [undefinied, updateUndefinied] = useState(false)

  function find(slug) {
    return articles.find(p => p.acf.link === slug);
  }

  const previusPage = () => {
    const prev = document.referrer
    const currentHost = window.location.hostname
    if (prev.search(currentHost) &&  prev !== '') {
      window.history.back()
    } else {
      history.push('/')
    }
  }

  useEffect(() => {
    setTimeout(() => {
      updateUndefinied(true)
    }, 3500)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  })

  useEffect(() => {
    articles.map((p, i) => {
      if(p.acf.link === match.params.slug) {
        const url = articles[i + 1] ? articles[i + 1].acf.link : articles[0].acf.link
        updateNextArticle(url)
      }
    })
  }, [articles, match.params.slug])

  let article = find(match.params.slug);
  return (
    <BG
      color={article !== undefined ? article.acf.bg_color : '#F9F8F6'}
    >
      {
        (undefinied && article === undefined) && (
          <NotFound />
        )
      }
      { article !== undefined && 
        <Container dontReverse={true} isMinHeight={true}>
          <SEO 
            title={article.acf.seo ?article.acf.seo.title : ''}
            description={article.acf.seo ? article.acf.seo.description : ''}
            keywords={article.acf.seo ? article.acf.seo.keywords : ''}
          />
          <MenuIcon isArticle color={article !== undefined ? article.acf.bg_color : '#F9F8F6'} />
          <BackMobile>
            <a onClick={() => previusPage()} href><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
                <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke={article.acf.special_color} strokeWidth="2.3"/>
              </svg>
            </a>
          </BackMobile>
          <Link 
            className={`contact-button contact-button--dark`} 
            exact to="/kontakt">kontakt</Link>
          <TitleWrap isTransform={true}>
            <TitleCenter color={article.acf.special_color}>
              <span style={{
                color: article.acf.special_color
              }} className="icon-signet"></span>
            </TitleCenter>
          </TitleWrap>  
          <Half mobileInitial={true} fullHeight={true}>
            <ShowDesktop>
              <HomeIntroTitle color={article.acf.special_color}>
                { article.acf.title }
              </HomeIntroTitle>
              <HomeIntroPlus>
                <svg xmlns="http://www.w3.org/2000/svg" width="44.44" height="44.439" stroke={article.acf.special_color} viewBox="0 0 44.44 44.439">
                  <g transform="translate(-771.5 -71)">
                    <line x2="44.44" transform="translate(771.5 93.22)" fill="none" strokeWidth="10"/>
                    <line x2="44.44" transform="translate(793.72 71) rotate(90)" fill="none" strokeWidth="10"/>
                  </g>
                </svg>
              </HomeIntroPlus>
            </ShowDesktop>
          </Half>
          <Half mobileInitial={true} fullHeight={false}>
            <div itemscope itemtype="http://schema.org/NewsArticle">
            <TextWrap>
              {match.params.slug === 'fenymenalni' && <GoldenArrow>
                <GoldenArrowImageWrapper>
                  <GoldenArrowImage src={GoldenArrowImg} />
                </GoldenArrowImageWrapper>
                <GoldenArrowWrapper>
                  <GoldenArrowTitleDescription>To właśnie ten projekt, poddany ocenie efektywności<br/>
                  strategii i kreatywności, w prestiżowym konkursie</GoldenArrowTitleDescription>
                  <GoldenArrowTitle>Golden Arrow</GoldenArrowTitle>
                  <GoldenArrowTitleDescription>
                    zwycieżył w kategorii
                  </GoldenArrowTitleDescription>
                  <GoldenArrowDescription>
                    Digital Marketing - Kampania internetowa
                  </GoldenArrowDescription>
                </GoldenArrowWrapper>
              </GoldenArrow>}
              
              
                          <ArticleTitle
                            isAuthor={true}
                            hasCharts={article.acf.intro.charts}
                          >
                            <ArticleTop 
                              style={{
                                color: article.acf.text_color
                              }}
                            >
                              <strong itemprop="author">{article.acf.author}</strong> <span itemprop="datePublished" content={`${article.acf.date}`}>{article.acf.date}</span>
                            </ArticleTop>
                            <ArticleTitleWrap
                              hasCharts={article.acf.intro.charts}
                              color={ article.acf.text_color }
                            >
                              <ArticleTitleTitle isAuthor={true}><h1 itemprop="headline">{article.acf.intro.title}</h1></ArticleTitleTitle>
                              {article.acf.intro.description && <div itemprop="description"><ArticleTitleDescription><Hrefs text={article.acf.intro.description} /></ArticleTitleDescription></div>}
                            </ArticleTitleWrap>
                            {
                              article.acf.intro.charts === true && <ArticleTitleDonuts>
                                {
                                  article.acf.intro.donuts.map(donut => {
                                    return (
                                      <DonutChart 
                                        size={117}
                                        stroke={article.acf.text_color ? article.acf.text_color : article.acf.color}
                                        bgColor={article !== undefined ? article.acf.bg_color : '#ffffff'}
                                        text={donut.in_chart}
                                        max={donut.in_max}
                                        specialChar={donut.special_char}
                                        textColor={article.acf.text_color ? article.acf.text_color : '#232328'}
                                        undertext={donut.under_chart}
                                      />
                                    )
                                  })
                                }
                              </ArticleTitleDonuts>
                            }
                          </ArticleTitle>
              </TextWrap>
              <div itemprop="articleBody">
                {
                  article.acf.article.map((el, index) => {
                    switch (el.acf_fc_layout) {
                      case 'text':
                        return (
                          <TextWrap>
                            <ArticleText color={ article.acf.text_color } right={el.right}>
                              <Hrefs text={el.content} />
                            </ArticleText>
                          </TextWrap>
                        )
                      case 'title':
                        return (
                          <TextWrap>
                            <ArticleTitle
                              hasCharts={el.charts}
                            >
                              <ArticleTitleWrap
                                hasCharts={el.charts}
                                color={ article.acf.text_color }
                              >
                                <ArticleTitleTitle ><h1 itemprop="headline">{el.title}</h1></ArticleTitleTitle>
                                {el.description && <div itemprop="description"><ArticleTitleDescription>{el.description}</ArticleTitleDescription></div>}
                              </ArticleTitleWrap>
                              {
                                el.charts === true && <ArticleTitleDonuts>
                                  {
                                    el.donuts.map(donut => {
                                      return (
                                        <DonutChart 
                                          size={117}
                                          stroke={article.acf.text_color ? article.acf.text_color : article.acf.color}
                                          bgColor={article !== undefined ? article.acf.bg_color : '#ffffff'}
                                          text={donut.in_chart}
                                          max={donut.in_max}
                                          specialChar={donut.special_char}
                                          textColor={article.acf.text_color ? article.acf.text_color : '#232328'}
                                          undertext={donut.under_chart}
                                        />
                                      )
                                    })
                                  }
                                </ArticleTitleDonuts>
                              }
                            </ArticleTitle>
                          </TextWrap>
                        )
                      case 'img':
                        return (<TextWrap rightZero={true}>
                          <div itemprop="image" itemscope itemtype="http://schema.org/ImageObject">
                            <meta itemprop="height" content={el.img.height}></meta>
                            <meta itemprop="width" content={el.img.width}></meta>
                            <meta itemprop="url" content={el.img.url}></meta>
                            <ArticleImg src={el.img.url} alt={el.img.alt} />
                          </div>
                        </TextWrap>)
                      case 'vimeo':
                        return (<Player video={{
                          src: el.url,
                          color: article.acf.special_color
                        }}
                        video_popup={{
                          src: el.url_popup,
                        }}
                        id={el.id}
                        nonMarginable />)
                      default:
                        return false;
                    }
                  })
                }
              </div>
            </div>
            <TextWrap>
              { nextArticle && <Next color={article.acf.special_color}>
                <Link to={`/artykul/${nextArticle}`}>
                  Następny news<br/>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                    <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill={article.acf.special_color}/>
                  </svg>
                </Link>
              </Next>}
              <ArticleBottomContainer>
                <More article={true} color={article.acf.special_color}>
                  <a onClick={() => previusPage()} href><svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                    <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={article.acf.special_color}/>
                  </svg> powrót
                  </a>
                </More>
                <More hideMobile={true} noneRotate={true} article={true} color={article.acf.special_color}>
                  { nextArticle && <Link to={`/artykul/${nextArticle}`}> następny news <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                    <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={article.acf.special_color}/>
                  </svg>
                  </Link>}
                </More>
              </ArticleBottomContainer>
              
            </TextWrap>
          </Half>
        </Container>
      }
     
    </BG>
  )
}

export default withRouter(connect(mapStateToProps, null)(Article));

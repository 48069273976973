import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import demo_2_a from '../../../../assets/case-study/valspar/demo-board/01A.png'
import demo_2_b from '../../../../assets/case-study/valspar/demo-board/01B.png'
import demo_2_c from '../../../../assets/case-study/valspar/demo-board/01C.png'

import social_1_a from '../../../../assets/case-study/valspar/social-media/01A.png'
import social_1_b from '../../../../assets/case-study/valspar/social-media/01B.png'
import social_1_c from '../../../../assets/case-study/valspar/social-media/01C.png'

import castorama_gif from '../../../../assets/case-study/valspar/castorama/gif.gif'

import castorama_1_a from '../../../../assets/case-study/valspar/castorama/01/01A.png'
import castorama_1_b from '../../../../assets/case-study/valspar/castorama/01/01B.png'
import castorama_1_c from '../../../../assets/case-study/valspar/castorama/01/01C.png'

import castorama_2_a from '../../../../assets/case-study/valspar/castorama/02/02A.png'
import castorama_2_b from '../../../../assets/case-study/valspar/castorama/02/02B.png'
import castorama_2_c from '../../../../assets/case-study/valspar/castorama/02/02C.png'


import { ValsparGlobal } from './valrspar.styled';
import SEO from '../../../seo/seo';
import ValsparLogo from '../../carousel/logo/valspar';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.valspar.textColor;
  const colorBg = Routes.portfolio.childs.valspar.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round(new Date(date).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 10) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.valspar.name}
        description={Routes.portfolio.childs.valspar.description}
        keywords={Routes.portfolio.childs.valspar.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <ValsparGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'social-media',
            name: 'social media',
          },
          {
            slug: 'animacje',
            name: 'animacje',
          },
          {
            slug: 'print',
            name: 'print',
          },
          {
            slug: 'wsparcie-graficzne',
            name: 'wsparcie graficzne',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="vaspar__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <ValsparLogo/>
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            wszystko dobrze się maluje Valspar to marka farb, która stworzy każdy kolor dostępny dla ludzkiego oka, czyli na oko ok. 2,2 miliona. my natomiast tworzymy dla nich komunikację, której ludzkie oko nie będzie chciało pominąć. 
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  wszystko dobrze się maluje
                </Title>
                <Text>
                  Valspar to marka farb, która stworzy każdy kolor dostępny dla ludzkiego oka, czyli na oko ok. 2,2 miliona. my natomiast tworzymy dla nich komunikację, której ludzkie oko nie będzie chciało pominąć.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2019}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2024-07-13', 33, 1)}
                  max={getProjects('2024-07-13', 33, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={270}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="social-media"
          id="social-media"
          className="animated fadeInUp faster"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              Instagram i influencer marketing
            </SubTitle>
            <Text>
              każdy, kto mówi, że liczy się wnętrze, powinien zajrzeć na Instagram <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/farbyvalspar/">@farbyvalspar</a>, bo znajdzie tam wnętrza przepiękne. zewnętrza też, bo Valspar oferuje również farby, które można stosować na zewnątrz. a oprócz tego trendy, inspiracje i praktyczne porady. wszystko to, co przyda się tym, którzy planują zmiany w swoim mieszkaniu.  
              <br /><br />
              w inspirowaniu odbiorców do małych eksperymentów z kolorami, pomagają nam internetowi twórcy. a efektem naszej współpracy są krótkie reels z metamorfozami wnętrz i relacjami z zakupów w sklepach Castorama. 
            </Text>
          </TextWrap>
          <div className="valspar__social__img-1">
            <ImgSection
              baseImg={social_1_a}
              secondImg={social_1_b}
              thirdImg={social_1_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="animacje"
          id="animacje"
          className="valspar__section"
        >
          <TextWrap>
            <SubTitle className="valspar__right">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              animacja
            </SubTitle>
            <Text className="valspar__right">
              kupujesz farbę, malujesz ścianę i okazuje się, że jednak nie to? no to przypał. chyba, że to farba Valspar, to wtedy nieprzypał, bo za darmo możesz wymienić stary kolor na nowy. o tym właśnie opowiada animacja stworzona dla sklepów Castorama i użytku wewnętrznego marki.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/984783916?h=3610d76bba&amp',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="print"
          id="print"
          className="valspar__section"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              demo board
            </SubTitle>
            <Text>
              przychodzi przedstawiciel handlowy do klienta i proszę, jaki ma elegancki demo board. z takim demo boardem można by demonstrować i demonstrować zalety farb, a klient nie musi wierzyć na słowo, bo widzi różnice między produktami na własne oczy. i wszyscy są usatysfakcjonowani, z zadowoleniem kiwają głowami, po czym żyją długo i szczęśliwie.
            </Text>
          </TextWrap>
          <div className="valspar__print__img-1">
            <ImgSection
              baseImg={demo_2_c}
              thirdImg={demo_2_b}
              secondImg={demo_2_a}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="wsparcie-graficzne"
          id="wsparcie-graficzne"
        >
          <TextWrap>
            <SubTitle className="valspar__right">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              współpraca z Castorama
            </SubTitle>
            <Text className="valspar__right">
              farby Valspar są dostępne jedynie w sklepach Castorama i tylko tam możecie też zmieszać dowolny kolor lub oddać farbę, jeśli jej odcień na ścianie nie jest tym wymarzonym. a żeby wiedziało o tym więcej osób, stworzyliśmy animację dla sklepów i posty na Pinterest, które Castorama opublikowała na swoim profilu.
            </Text>
          </TextWrap>
          
          <div className="valspar__castorama__img-1">
            <ImgSection
              baseImg={castorama_1_a}
              thirdImg={castorama_1_b}
              secondImg={castorama_1_c}
            />
          </div>
          <div className="valspar__castorama__img-2"
            style={{
              zIndex: 10,
              position: 'relative',
            }}
          >
            <ImgSection
              baseImg={castorama_gif}
            />
          </div>
          <div className="valspar__castorama__img-2">
            <ImgSection
              baseImg={castorama_2_a}
              thirdImg={castorama_2_c}
              secondImg={castorama_2_b}
            />
          </div>
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={Routes.portfolio.childs.brita.slug}>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
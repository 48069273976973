import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { More } from '../../../ui/more/more.styled';
import MainTitle from '../../../ui/title/title';
import { TextText } from '../../../text-text/text-text.styled';
import Routes from '../../../../routes/routes';
import { HomeMain, HomeMainAnothers } from './../home/home.styled';
import SEO from '../../../seo/seo';

const mapStateToProps = state => {
  return {colors: state.colors};
};

const Home2 = ( {colors} ) => {
  const childs = Routes.portfolio.childs
  const colorBg = colors.portfolio ? colors.portfolio.color : '';

  useEffect(() => {
    document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#232328' );
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })
  return (
    <div>
      <SEO
        title={Routes.portfolio.childs.pozostali.name}
        description={Routes.portfolio.childs.pozostali.description}
        keywords={Routes.portfolio.childs.pozostali.keywords}
      />
      <MainTitle
        delay={0.2}
        absolute={true}
        left={''}
        right='ni&nbsp;nam&nbsp;zaufali'
      />
      <TextText mobileHide={true} bold={true} 
        className="animated fadeInUp faster" 
        style={{
          animationDelay: `0.8s`
        }}
        left="0%">
         <p>i co? jeszcze Ci mało? poznaj wszystkich, którzy nam zaufali. niech ich marki przemówią.</p>
          <More dontHideBr={true} color={colors.portfolio ? colors.portfolio.color : ''}>
            <Link
              style={{
                color: '#005FFF',
              }}
              to={'/#klienci'}>poznaj naszych klientów <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={'#005FFF'}/>
              </svg>
            </Link>
          </More>
      </TextText>

      <HomeMain>
          {
            Object.keys(childs).map((el, i) => {
              let l = 3.2 + (i /10)
              if ( i > 0 ) {
                return (
                  <Link
                    to={childs[el].slug}
                    className="carousel__item animated fadeInUp faster"
                    style={{
                      animationDelay: `${l}s`
                    }}
                  >
                    { childs[el].logo }
                  </Link>
                )
              }

              return false
            })
          }
      </HomeMain>
      <HomeMainAnothers>
        <div
          className="carousel__item animated fadeInUp faster"
          style={{
            animationDelay: `3.8s`
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="357.825" height="88.874" viewBox="0 0 357.825 88.874"><g id="NIVEA_wordmark" transform="translate(-522.509 -576.811)"><path id="A" d="M684.807,638.908c0,.059,9.612-19.7,9.612-19.7s9.428,19.76,9.428,19.7Zm9.612-62.1c-15.574,27.479-46.216,83.161-46.216,83.161h26.261l4.26-8.369h31.357l4.146,8.369h26.438L694.419,576.81Z" transform="translate(139.668 0)" fillRule="evenodd"></path><path id="E" d="M671.184,599.306H647.031v10.771l24.153-.017v14.56H647.031v11.953h24.153v20.07H621.95V579.806h49.234v19.5Z" transform="translate(110.497 3.329)"></path><path id="V" d="M622.284,614.239l17.225-34.433h26.776c.1,0-44,82.549-44,82.549l-43.946-82.549h26.8s15.777,32.028,17.145,34.433" transform="translate(62.036 3.329)" fillRule="evenodd"></path><path id="I" d="M589.61,656.642H564.493V579.806s25.117.091,25.117,0v76.836Z" transform="translate(46.652 3.329)" fillRule="evenodd"></path><path id="N" d="M563.328,612.6V582.872h25.12v82.55l-40.79-35.481v29.768h-25.15V577.047L563.328,612.6Z" transform="translate(0 0.264)" fillRule="evenodd"></path></g></svg>
        </div>
        <div
          className="carousel__item animated fadeInUp faster"
          style={{
            animationDelay: `3.9s`
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="357.825" height="88.874" viewBox="0 0 357.825 88.874"><g id="NIVEA_wordmark" transform="translate(-522.509 -576.811)"><path id="A" d="M684.807,638.908c0,.059,9.612-19.7,9.612-19.7s9.428,19.76,9.428,19.7Zm9.612-62.1c-15.574,27.479-46.216,83.161-46.216,83.161h26.261l4.26-8.369h31.357l4.146,8.369h26.438L694.419,576.81Z" transform="translate(139.668 0)" fillRule="evenodd"></path><path id="E" d="M671.184,599.306H647.031v10.771l24.153-.017v14.56H647.031v11.953h24.153v20.07H621.95V579.806h49.234v19.5Z" transform="translate(110.497 3.329)"></path><path id="V" d="M622.284,614.239l17.225-34.433h26.776c.1,0-44,82.549-44,82.549l-43.946-82.549h26.8s15.777,32.028,17.145,34.433" transform="translate(62.036 3.329)" fillRule="evenodd"></path><path id="I" d="M589.61,656.642H564.493V579.806s25.117.091,25.117,0v76.836Z" transform="translate(46.652 3.329)" fillRule="evenodd"></path><path id="N" d="M563.328,612.6V582.872h25.12v82.55l-40.79-35.481v29.768h-25.15V577.047L563.328,612.6Z" transform="translate(0 0.264)" fillRule="evenodd"></path></g></svg>
        </div>
        <div
          className="carousel__item animated fadeInUp faster"
          style={{
            animationDelay: `4s`
          }} 
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="357.825" height="88.874" viewBox="0 0 357.825 88.874"><g id="NIVEA_wordmark" transform="translate(-522.509 -576.811)"><path id="A" d="M684.807,638.908c0,.059,9.612-19.7,9.612-19.7s9.428,19.76,9.428,19.7Zm9.612-62.1c-15.574,27.479-46.216,83.161-46.216,83.161h26.261l4.26-8.369h31.357l4.146,8.369h26.438L694.419,576.81Z" transform="translate(139.668 0)" fillRule="evenodd"></path><path id="E" d="M671.184,599.306H647.031v10.771l24.153-.017v14.56H647.031v11.953h24.153v20.07H621.95V579.806h49.234v19.5Z" transform="translate(110.497 3.329)"></path><path id="V" d="M622.284,614.239l17.225-34.433h26.776c.1,0-44,82.549-44,82.549l-43.946-82.549h26.8s15.777,32.028,17.145,34.433" transform="translate(62.036 3.329)" fillRule="evenodd"></path><path id="I" d="M589.61,656.642H564.493V579.806s25.117.091,25.117,0v76.836Z" transform="translate(46.652 3.329)" fillRule="evenodd"></path><path id="N" d="M563.328,612.6V582.872h25.12v82.55l-40.79-35.481v29.768h-25.15V577.047L563.328,612.6Z" transform="translate(0 0.264)" fillRule="evenodd"></path></g></svg>
        </div>
        <div
          className="carousel__item animated fadeInUp faster"
          style={{
            animationDelay: `4.1s`
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="357.825" height="88.874" viewBox="0 0 357.825 88.874"><g id="NIVEA_wordmark" transform="translate(-522.509 -576.811)"><path id="A" d="M684.807,638.908c0,.059,9.612-19.7,9.612-19.7s9.428,19.76,9.428,19.7Zm9.612-62.1c-15.574,27.479-46.216,83.161-46.216,83.161h26.261l4.26-8.369h31.357l4.146,8.369h26.438L694.419,576.81Z" transform="translate(139.668 0)" fillRule="evenodd"></path><path id="E" d="M671.184,599.306H647.031v10.771l24.153-.017v14.56H647.031v11.953h24.153v20.07H621.95V579.806h49.234v19.5Z" transform="translate(110.497 3.329)"></path><path id="V" d="M622.284,614.239l17.225-34.433h26.776c.1,0-44,82.549-44,82.549l-43.946-82.549h26.8s15.777,32.028,17.145,34.433" transform="translate(62.036 3.329)" fillRule="evenodd"></path><path id="I" d="M589.61,656.642H564.493V579.806s25.117.091,25.117,0v76.836Z" transform="translate(46.652 3.329)" fillRule="evenodd"></path><path id="N" d="M563.328,612.6V582.872h25.12v82.55l-40.79-35.481v29.768h-25.15V577.047L563.328,612.6Z" transform="translate(0 0.264)" fillRule="evenodd"></path></g></svg>
        </div>
      </HomeMainAnothers>
    </div>
  )
}

export default connect(mapStateToProps, null)(Home2)
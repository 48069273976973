import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import kujawski_1_a from '../../../../assets/case-study/kruszwica/01/01A.png'
import kujawski_1_b from '../../../../assets/case-study/kruszwica/01/01B.png'
import kujawski_1_c from '../../../../assets/case-study/kruszwica/01/01C.png'

import smakowita_2_a from '../../../../assets/case-study/kruszwica/02/02A.png'
import smakowita_2_b from '../../../../assets/case-study/kruszwica/02/02B.png'
import smakowita_3_a from '../../../../assets/case-study/kruszwica/02/03A.png'
import smakowita_3_b from '../../../../assets/case-study/kruszwica/02/03B.png'
import smakowita_3_c from '../../../../assets/case-study/kruszwica/02/03C.png'
import smakowita_4_a from '../../../../assets/case-study/kruszwica/02/04A.png'
import smakowita_4_b from '../../../../assets/case-study/kruszwica/02/04B.png'

import optima_1_a from '../../../../assets/case-study/kruszwica/03/01A.png'
import optima_1_b from '../../../../assets/case-study/kruszwica/03/01B.png'
import optima_1_c from '../../../../assets/case-study/kruszwica/03/01C.png'

import eb_1_a from '../../../../assets/case-study/kruszwica/04/01A.png'
import eb_1_b from '../../../../assets/case-study/kruszwica/04/01B.png'
import eb_2_a from '../../../../assets/case-study/kruszwica/04/02A.png'
import eb_2_b from '../../../../assets/case-study/kruszwica/04/02B.png'

import standardy_1_a from '../../../../assets/case-study/kruszwica/05/01A.png'
import standardy_1_b from '../../../../assets/case-study/kruszwica/05/01B.png'
import standardy_1_c from '../../../../assets/case-study/kruszwica/05/01C.png'
import standardy_2_a from '../../../../assets/case-study/kruszwica/05/02A.png'
import standardy_2_b from '../../../../assets/case-study/kruszwica/05/02B.png'
import standardy_3_a from '../../../../assets/case-study/kruszwica/05/03A.png'
import standardy_3_b from '../../../../assets/case-study/kruszwica/05/03B.png'
import standardy_3_c from '../../../../assets/case-study/kruszwica/05/03C.png'

import { AmicaGlobal } from './kruszwica.styled';
import SEO from '../../../seo/seo';
import Kruszwica from '../../carousel/logo/kruszwica';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.kruszwica.textColor;
  const colorBg = Routes.portfolio.childs.kruszwica.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round(new Date(date).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 10) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.kruszwica.name}
        description={Routes.portfolio.childs.kruszwica.description}
        keywords={Routes.portfolio.childs.kruszwica.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <AmicaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'kujawski',
            name: 'Kujawski',
          },
          {
            slug: 'smakowita',
            name: 'Smakowita',
          },
          {
            slug: 'optima',
            name: 'Optima',
          },
          {
            slug: 'employer-branding',
            name: 'employer branding',
          },
          {
            slug: 'standardy',
            name: 'standardy',
          }
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Kruszwica/>
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            oliwa zawsze sprawiedliwa, i wie, kto dobrze ją obsłuży w BTLu. i tak od kilkunastu lat, więc to wcale nie jest masło maślane.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  oliwa zawsze sprawiedliwa
                </Title>
                <Text>
                  i wie, kto dobrze ją obsłuży w BTLu. i tak od kilkunastu lat, więc to wcale nie jest masło maślane.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2008}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2020-04-13', 248, 1)}
                  max={getProjects('2020-04-13', 248, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={192}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  color={color}
                  content="Agencja Pomarańcza jest godnym zaufania i polecenia partnerem biznesowym, który z pasją realizuje powierzane projekty. Współpraca przebiega zawsze profesjonalnie oraz z zachowaniem terminowości i wysokich standardów. Cenimy jej kompetencje, a także zaangażowanie pracowników. Kreatywność oraz niezawodność budują przewagę Agencji na rynku usług marketingowych."
                  author="Małgorzata Adamczyk"
                  company="Specjalista ds. Aktywacji Marek"
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kujawski"
          id="kujawski"
          className="animated fadeInUp faster kruszwica__kujawski"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              znasz “Kujawskiego”?<br/> spróbuj każdego!
            </SubTitle>
            <Text>
            nasz koncept “Spróbuj każdego! Od ciepłego do zimnego” oraz key visual najpierw zwyciężyły w konkursie... 
            </Text>
          </TextWrap>
          <div className="kruszwica__kujawski__img-1">
            <ImgSection
              baseImg={kujawski_1_a}
              secondImg={kujawski_1_b}
              thirdImg={kujawski_1_c}
              layerUp={true}
            />
          </div>
          <TextWrap>
            <Text className="kruszwica__kujawski__text-1">
              ...a potem pojawiły się na wielu rodzajach nośników POS w sklepach w całej Polsce!
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="smakowita"
          id="smakowita"
          className="kruszwica__smakowita"
        >
          <TextWrap
            
          >
            <SubTitle className="kruszwica__right">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              Smakowita ma 4 ziarna, a my dorzucamy swoje ziarenko kreatywności
            </SubTitle>
            <Text className="kruszwica__right">
              koncepcyjne zabudowy chłodziarek POS i displayów na potrzeby launchu nowego wariantu tej popularnej margaryny.
            </Text>
          </TextWrap>
          <div className="kruszwica__smakowita__img-1">
            <ImgSection
              baseImg={smakowita_2_a}
              thirdImg={smakowita_2_b}
              layerUp={true}
            />
          </div>
          <TextWrap>
            <Text>
              ideę wsparliśmy projektami OOH..
            </Text>
          </TextWrap>
          <div className="kruszwica__smakowita__img-2">
            <ImgSection
              baseImg={smakowita_3_a}
              secondImg={smakowita_3_b}
              thirdImg={smakowita_3_c}
            />
          </div>
          <TextWrap>
            <Text className="kruszwica__smakowita__text-1">
              ...prasą i niestandardowymi małymi nośnikami POS. wobbler też może być niezwykły. 
            </Text>
          </TextWrap>
          <div className="kruszwica__smakowita__img-3">
            <ImgSection
              baseImg={smakowita_4_a}
              secondImg={smakowita_4_b}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="optima"
          id="optima"
          className="kruszwica__optima"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              Optima DHA launch
            </SubTitle>
            <Text>
              kolejny zwycięski projekt przetargowy na ogólnopolską kampanię BTL/POS w największych sieciach handlowych oraz w komunikacji B2B i w trakcie kluczowych wydarzeń związanych z wprowadzeniem nowego wariantu margaryny Optima na rynek. 
            </Text>
          </TextWrap>
          <div className="kruszwica__optima__img-1">
            <ImgSection
              baseImg={optima_1_a}
              secondImg={optima_1_b}
              thirdImg={optima_1_c}
            />
          </div>
          <TextWrap>
            <Text className="kruszwica__right"> 
              na elementy kampanii składały się nośniki POS, materiały drukowane, reklamy prasowe i oczywiście landing page kampanii.<br/><Link to="/artykul/optima-dha">dowiedz się więcej na temat kampanii</Link>. 
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="employer-branding"
          id="employer-branding"
          className="kruszwica__eb"
        >
          <TextWrap>
            <SubTitle className="kruszwica__right">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              projekty wysokich lotów
            </SubTitle>
            <Text className="kruszwica__right">
              od lat obsługujemy także działania marki w obszarze employer brandingu. “Staż wysokich lotów” to jeden z takich projektów, oparty o naszą koncepcję, key visual i przełożony na szereg nośników wykorzystywanych w komunikacji marki pracodawcy.
            </Text>
          </TextWrap>
          <div className="kruszwica__eb__img-1">
            <ImgSection
              baseImg={eb_1_a}
              secondImg={eb_1_b}
            />
          </div>
          <div className="kruszwica__eb__img-2">
            <ImgSection
              baseImg={eb_2_a}
              thirdImg={eb_2_b}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="standardy"
          id="standardy"
          className="kruszwica__pos"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>5.</SubTitleNumber>
              standardowe nośniki POS
            </SubTitle>
            <Text>
              i setki sytuacji, w których musimy się wykazać sprawnością i zwinnością oraz umiejętnością szybkiego przygotowania materiałów graficznych na prostsze, standardowe nośniki POS.
            </Text>
          </TextWrap>
          <div className="kruszwica__pos__img-1">
          <ImgSection
              layerUp
              baseImg={standardy_1_c}
              secondImg={standardy_1_a}
              thirdImg={standardy_1_b}
            />
          </div>
          <TextWrap>
            <Text className="kruszwica__right">
              albo formatów digitalowych na potrzeby komunikacji marek w internecie.
            </Text>
          </TextWrap>
          <div className="kruszwica__pos__img-2">
          <ImgSection
              baseImg={standardy_2_a}
              secondImg={standardy_2_b}
            />
          </div>
          <div className="kruszwica__pos__img-3">
            <ImgSection
              baseImg={standardy_3_a}
              secondImg={standardy_3_b}
              thirdImg={standardy_3_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={Routes.portfolio.childs.pozostali.slug}>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
import React, { useEffect, useState } from 'react'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LazyLoad from 'react-lazy-load';

import { More } from '../ui/more/more.styled';
import { Container } from '../grid/container/Container.styled';
import { BG, BGGradient, BGWrapper } from '../grid/bg/bg.style';
import { HalfFull } from '../grid/half/Half.styled';
import MainTitle from '../ui/title/title';
import { ClientsGrid, ClientsItem, ClientsItemImg, ClientsLogoMobile, ClientsText } from './clients.styled';
import { logos, homeScroll } from '../../actions';
import { TextText } from '../text-text/text-text.styled';
import BlockQuote from '../portfolio/block-quote/block-quote';
import { Text } from '../portfolio/text/text.styled';
import Hrefs from '../hrefs/hrefs';
import Routes from '../../routes/routes';
import MenuIcon from '../menu-icon/menu-icon';
import { CloseMenu } from '../menu/menu.styled';
import { MIN_ANIMATION } from '../../config/config';
import Scroll from '../ui/scroll/scroll';
import { isMobileOnly } from 'react-device-detect';

const mapStateToProps = state => {
  return { intro : state.intro, logos : state.logos, colors: state.colors };
};

function mapDispatchToProps(dispatch) {
  return {
    updateLogos: animation => dispatch(logos(animation)),
    homeScrollUpdate: homeScrollUpdate => dispatch(homeScroll(homeScrollUpdate))
  };
}

function shuffle(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

const Clients = ({showHide, intro, logos, updateLogos, animationOut, colors, homeScrollUpdate, isFirst, firstTime}) => {
  let [elementsList, updateElementsList] = useState([])
  let [click, updateClick] = useState(false)
  let [current, updateCurrent] = useState(false)
  let [load, updateLoad] = useState(false)
  let [currentElement, updateCurrentElement] = useState(false)
  let [animationTime, updateAnimationTime] = useState(MIN_ANIMATION)

  const update = (el) => {
    // console.log(logos[el])
    updateCurrent(el)
    updateClick(false)

    setTimeout(() => {
      updateClick(true)
    }, 6000)
    if( el !== false && isMobileOnly ) {
      homeScrollUpdate(false)
    } else {
      homeScrollUpdate(true)
    }
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     updateClick(true)
  //   }, 6000)
  // }, [])

  useEffect(() => {
    if( firstTime ) {
      updateAnimationTime(0)
      updateClick(true)
    }
  }, [firstTime])

  useEffect(() => {
    if(click && !isMobileOnly) {
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * 18)
        updateCurrent(randomIndex)  
      }, 7500)
      return () => clearInterval(interval);
    }
  }, [click])

  useEffect(() => {
    if(elementsList.length > 0) {
      setTimeout(()=> {
        updateLoad(true)
        
      }, 3300)
    }
  }, [elementsList])

  useEffect(()=> {
    let works = true
    if( logos.length > 0 && works && load) {
      works = false
      const elements = elementsList
      const list = logos
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * 18)
        if(randomIndex !== parseInt(current) && currentElement !== randomIndex) {
          
          const listEl = list[randomIndex]
          function isNotActive(element, index, array) {
            return (element.active !== true);
          }
          const passed = elements.filter(isNotActive);
          const randomNew = passed[Math.floor(Math.random() * passed.length)]
          for(let i = 0; i < elements.length; i++) {
            if(elements[i].id === randomNew.id) {
              elements[i].active = true
            }
          }
          list[randomIndex] = randomNew
          for(let i = 0; i < elements.length; i++) {
            if(elements[i].id === listEl.id) {
              elements[i].active = false
            }
          }
          
          updateCurrentElement(randomIndex)
          updateElementsList(elements)
          updateLogos(list)
        }
      }, 1500)

      return () => clearInterval(interval);
    }
  },[logos, current, currentElement, load, elementsList, updateLogos])

  useEffect(()=> {
    if(Object.keys(intro).length > 0) {
      let elements = intro.clients
      elements.forEach((element, i) => {
        elements[i].id = i
      });
      
      elements = shuffle(elements)

      elements.forEach((element,i) => {
        if( i < 18) {
          elements[i].active = true
        } else {
          elements[i].active = false
        }
      })
      let list = []
      for(let i = 0; i < 18; i++) {
        elements[i].active = true
        list[i] = elements[i]
      }

      updateElementsList(elements)

      updateLogos(list)
    }
    
  }, [intro])

  const getLogo = (item) => {
    return item.logo_mobile ? item.logo_mobile : item.logo.url
  }

  return(
    <BGWrapper>
    <BGGradient>
    <BG
        style={{
          zIndex: 1,
          // background: 'rgb(35, 35, 40)',
          display: showHide ? 'block' : 'none'
        }}
        maxHeight={true}
      >
      <Scroll 
        isAbsolute={true}
        isWhite={true}
        isOnMobileAlso={true}
      />
      <MenuIcon
        isAbsolute={true}
        isWhite={true}
      />
      <Link
        style={{
          color: colors.portfolio ? colors.portfolio.color : ''
        }}
        className={`
        ${showHide ? 'animated fadeIn faster' : ''}
        ${animationOut ? 'animated fadeOut faster' : ''}
        contact-button contact-button--absolute`} 
        exact to={Routes.kontakt.slug}>kontakt</Link>    
      <MainTitle
        delay={0.1}
        color="#F9F8F6"
        sygnetColor={colors.portfolio ? colors.portfolio.color : ''}
        right='NI&nbsp;NAM&nbsp;ZAUFALI'
      />
      <Container>
        <HalfFull>
          <ClientsGrid id="logosWrapper">
            {
              logos.map((el, i) => {
                const time = load ? 0.3 : animationTime +  0.1 + (i / 30)
                return(
                  <ClientsItem
                    className={`animated ${showHide ? 'fadeIn' : ''} faster`}
                    style={{
                      animationDelay: `${ time }s`
                    }}
                    data-id={el.id}
                    data-i={i} 
                    key={el.id}
                    onClick={() => update(i)}
                  >
                    <LazyLoad>
                      <ClientsItemImg
                      active={i === current}
                      src={el.logo.url} alt={el.logo.alt} />
                    </LazyLoad>
                  </ClientsItem>
                )
              })
            }
          </ClientsGrid>
        </HalfFull>
        <HalfFull
          hardShowAtDesktop={true}
          style={{
            display: current !== false ? 'block' : 'none',
            // background: 'rgb(35, 35, 40)'
          }}
          isPopUp={true}>
          <CloseMenu
            hideOnDesktop={true}
            onClick={() => update(false)}>
            <svg width="35" height="35" viewBox="0 0 19.665 19.713">
              <g fill={colors.portfolio ? colors.portfolio.color : ''}>
                <g>
                  <path d="M11.613 9.43l-.067-.067-.065-.065-.067-.067-8.9-8.9-.066-.067-.066-.065-.066-.067a.374.374 0 0 0-.527.021L.153 1.79a.374.374 0 0 0-.022.527l.067.066.065.066.067.066 8.9 8.9.067.067.065.065.067.067a.372.372 0 0 0 .526-.021l1.637-1.637a.372.372 0 0 0 .021-.526z"/>
                  <path d="M8.051 9.43l.067-.067.065-.065.067-.067 8.9-8.9.067-.067.065-.065.067-.067a.373.373 0 0 1 .526.021l1.637 1.637a.375.375 0 0 1 .022.527l-.067.066-.065.066-.067.066-8.9 8.9-.067.067-.065.065-.067.067a.372.372 0 0 1-.526-.021L8.073 9.956a.373.373 0 0 1-.022-.526z"/>
                </g>
                <g>
                  <path d="M11.613 10.284l-.067.066-.065.066-.067.067-8.9 8.9-.066.067-.066.065-.066.067a.374.374 0 0 1-.527-.021L.153 17.924a.372.372 0 0 1-.022-.526l.067-.067.065-.065.067-.067 8.9-8.9.067-.067.065-.065.067-.067a.372.372 0 0 1 .526.022l1.637 1.636a.374.374 0 0 1 .021.526z"/>
                  <path d="M8.051 10.284l.067.066.065.066.067.067 8.9 8.9.067.067.065.065.067.067a.372.372 0 0 0 .526-.021l1.637-1.637a.373.373 0 0 0 .022-.526l-.067-.067-.065-.065-.067-.067-8.9-8.9-.067-.067-.065-.065-.067-.067a.372.372 0 0 0-.526.022L8.073 9.758a.375.375 0 0 0-.022.526z"/>
                </g>
              </g>
            </svg>
          </CloseMenu>
          <ClientsLogoMobile 
            mobile={logos[current] && logos[current].logo_mobile}
          >
            {
              current !== false && <img src={getLogo(logos[current])} alt={logos[current].logo.alt} />
            }
          </ClientsLogoMobile>
          <TextText
            homeMobileSmall
            clients
            className={`animated ${showHide ? 'fadeIn' : ''} faster`}
            style={{
              animationDelay: `${ animationTime + 0.8}s`
            }}
          >
            { current === false && <div>
                <Text home>
                  {
                    Object.keys(intro).length > 0 && intro.description.map(el=> {
                      switch (el.acf_fc_layout) {
                        case 'text' :
                          return(
                            <ClientsText key={el} center={true}>
                              <Hrefs text={el.text} />
                            </ClientsText>
                          )
                        case 'link' :
                          return (
                            <More key={el}
                              dontHideBr={true} color={colors.portfolio ? colors.portfolio.color : ''}>
                              {el.external_url ? <a style={{
                                  color: colors.portfolio ? colors.portfolio.color : '',
                                }} href={el.link}>{el.link_text} <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={colors.portfolio ? colors.portfolio.color : ''}/>
                                </svg></a> : <Link
                                style={{
                                  color: colors.portfolio ? colors.portfolio.color : '',
                                }}
                                to={el.link}>{el.link_text} <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={colors.portfolio ? colors.portfolio.color : ''}/>
                                </svg>
                              </Link>}
                            </More>
                          )
                        case 'quote' :
                            return (
                              <BlockQuote 
                                key={el}
                                isRight={true}
                                color={'#ffffff'}
                                content={el.content}
                                author={el.person}
                                company={el.company}
                              />
                            )
                        default:
                          return false
                      }
                    })
                  }
                </Text>
              </div>
            }
            { current !== false && <div>
              {
                logos[current].description.map(el=> {
                  switch (el.acf_fc_layout) {
                    case 'text' :
                      return(
                        <ClientsText home center={true}>
                          <Hrefs text={el.text} />
                        </ClientsText>
                      )
                    case 'link' :
                      return (
                        <More 
                              dontHideBr={true} color={colors.portfolio ? colors.portfolio.color : ''}>
                              {el.external_url ? <a style={{
                                  color: colors.portfolio ? colors.portfolio.color : '',
                                }} href={el.link}>{el.link_text} <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={colors.portfolio ? colors.portfolio.color : ''}/>
                                </svg></a> : <Link
                                style={{
                                  color: colors.portfolio ? colors.portfolio.color : '',
                                }}
                                to={el.link}>{el.link_text} <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
                                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={colors.portfolio ? colors.portfolio.color : ''}/>
                                </svg>
                              </Link>}
                            </More>
                      )
                    case 'quote' :
                        return (
                          <BlockQuote 
                            color={'#ffffff'}
                            content={el.content}
                            author={el.person}
                            company={el.company}
                          />
                        )
                    default:
                      return false
                  }
                })
              }
            </div>}
          </TextText>
        </HalfFull>
      </Container>
    </BG>
    </BGGradient>
    </BGWrapper>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients)
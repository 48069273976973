import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { More } from '../../components/ui/more/more.styled';
import styled from 'styled-components';
import MainTitle from '../../components/ui/title/title';
import Routes from '../../routes/routes';
import MenuIcon from '../../components/menu-icon/menu-icon';
import TextMobile from '../../components/text-mobile/text-mobile';
import { BG } from '../../components/grid/bg/bg.style';
import TextDesktopInfo from '../../components/text-desktop-info/text-desktop-info';
import { Container } from '../../components/grid/container/Container.styled';
import { MIN_ANIMATION } from '../../config/config';
import Map from '../../pages/map/map';

const mapStateToProps = state => {
  return {contact: state.contact, colors: state.colors};
};

export const Text = styled.p`
  font-family: europa, sans-serif;
  font-weight: 400;
  font-size: 1.15vw;
  letter-spacing: 1.75px;
  line-height: 1.875vw;
  color: #232328;
  margin: 0;
  padding-bottom: 1vw;
  position: relative;
  z-index: 4;
  
  @media (max-width: 1023px){
    font-size: 3.88vw;
    line-height: 5.55vw;
  }
   a {
    text-decoration: none;
   }
`; 

const SocialWrapper = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  font-size: 1.5625vw;
  line-height: 1.46;
  color: #0C00FF;
  margin-left: -10px;
`

const Social = styled.a`
  color: #0C00FF !important;
  margin-right: 10px;
  margin-left: 10px;

  /* &:visited {
    color: #0C00FF;
  } */

  @media (max-width: 1023px){
    font-size: 5.55vw;
    line-height: 6.94vw;
    padding-bottom: 3.61vw;
  }
`

const Info = styled.div`
  font-family: europa, sans-serif;
  font-weight: 300;
  color: #232328;
  font-size: 0.7vw;
  line-height: 1.78;

  @media (max-width: 1023px){
    font-size: 3vw;
  }
`

const SubTitle = styled.div`
  font-family: europa, sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 1.875vw; //36px
  line-height: 2.29vw; //44px
  color: #232328;
  padding-bottom: 1vw; //25px
  text-align: ${ props => props.alignRight ? 'right' : 'left'};

  @media (max-width: 1023px){
    text-align: center;
  }

  @media (max-width: 1023px){
    font-size: 5.55vw;
    line-height: 6.94vw;
    padding-bottom: 3.61vw;
  }
`

const Email = styled.a`
  color: #232328 !important;

  svg {
    width: 2.34vw;
    height: 1.675vw;
    margin-bottom: -0.5vw;

    @media (max-width: 1023px) {
      width: 7.7vw;
      height: 5.5vw;
    }
  }
`

const list = [
  {
    title: 'new business',
    small_title: true,
    descriptio: (
      <>
        <SubTitle>Radek Obek</SubTitle>
        <Text>
          <Email href="mailto:hello@pomarancza.pl"><svg width="60.794" height="42.591" viewBox="0 0 60.794 42.591">
            <g fill="#0C00FF">
              <path d="M60.794 42.591H0V0h60.794zM3.978 38.612h52.837V3.978H3.978z"/>
              <path d="M30.668 33.485L.578 3.395 3.391.583 30.668 27.86l27-27 2.813 2.813z"/>
              </g>
            </svg> hello@pomarancza.pl
          </Email>
        </Text>
        <Text>tel. +48 530 080 680</Text>
        <Text>ul. Przelot 17, 60-408 Poznań <Link to='/kontakt/mapa/'>
          <svg width="19.035" height="27.119" fill="#0C00FF" viewBox="0 0 19.035 27.119">
                <g transform="translate(-1645.046 -722.291)" >
                  <path d="M1653.741 748.438c-2.614-3.077-8.694-10.857-8.694-16.629a9.517 9.517 0 1 1 19.034 0c0 5.755-6.079 13.547-8.693 16.629l-.823.972zm.824-23.987a7.366 7.366 0 0 0-7.357 7.359c0 4.531 4.888 11.15 6.99 13.786l.367.459.365-.46c2.1-2.639 6.99-9.264 6.99-13.784a7.366 7.366 0 0 0-7.356-7.36z"/>
                  <circle cx="2.653" cy="2.653" r="2.653" transform="translate(1651.911 729.781)"/>
            </g>
          </svg></Link>
        </Text>
        <Text>
          <SocialWrapper>
            <Social target="_blank" href="https://www.facebook.com/AgencjaReklamowaPomarancza">fb</Social> | 
            <Social target="_blank" href="https://pl.linkedin.com/company/pomarancza">linkedin</Social> | 
            <Social target="_blank" href="https://www.instagram.com/pomarancza_poznan/">insta</Social>
          </SocialWrapper>
        </Text>
        <Text>
          <Info>Pomarańcza sp. z o.o. NIP: 7811987297, REGON: 382310629, Sąd Rejonowy Poznań Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy, KRS 0000766064, Kapitał zakładowy: 5000 PLN</Info>
        </Text>
      </>
    ),
    seo: {
      title: 'Kontakt - Pomarańcza',
      description: 'Kontakt z Pomarańcza. Z chęcią odpowiemy na wszystkie Twoje pytania. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
      keywords: '',
    }
  },
  {
    title: 'administracja',
    small_title: true,
    descriptio: (
      <>
        <SubTitle>sekretariat</SubTitle>
        <Text>
          <Email href="mailto:sekretariat@pomarancza.pl"><svg width="60.794" height="42.591" viewBox="0 0 60.794 42.591">
            <g fill="#0C00FF">
              <path d="M60.794 42.591H0V0h60.794zM3.978 38.612h52.837V3.978H3.978z"/>
              <path d="M30.668 33.485L.578 3.395 3.391.583 30.668 27.86l27-27 2.813 2.813z"/>
              </g>
            </svg> sekretariat@pomarancza.pl
          </Email>
        </Text>
      </>
    ),
    seo: {
      title: 'Sekretariat i biuro - Pomarańcza',
      description: 'Sprawy organizacyjne, administracyjne? Skontaktuj się z naszym sekretariatem. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
      keywords: ''
    }
  },
  {
    title: 'podwykonawcy',
    small_title: true,
    descriptio: (
      <>
        <SubTitle>współpraca</SubTitle>
        <Text>
          <Email href="mailto:ofertyB2B@pomarancza.pl"><svg width="60.794" height="42.591" viewBox="0 0 60.794 42.591">
            <g fill="#0C00FF">
              <path d="M60.794 42.591H0V0h60.794zM3.978 38.612h52.837V3.978H3.978z"/>
              <path d="M30.668 33.485L.578 3.395 3.391.583 30.668 27.86l27-27 2.813 2.813z"/>
              </g>
            </svg> ofertyB2B@pomarancza.pl
          </Email>
        </Text>
      </>
    ),
    seo: {
      title: 'Współpraca B2B - Pomarańcza',
      description: 'Chcesz z nami współpracować w modelu B2B? Zapraszamy do kontaktu! Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
      keywords: ''
    }
  },
  {
    title: 'praca?',
    small_title: true,
    descriptio: (<>
      <SubTitle>kariera</SubTitle>
      <More
        style={{
          paddingTop: 0,
        }}
        contact
        dontHideBr={true} color="#232328">
        <Link
          to='/kogo-szukamy/'>
            <span style={{
              color: '#232328',
              fontWeight: 400
            }}>przejdź tutaj</span> <svg xmlns="http://www.w3.org/2000/svg" width="24.295" height="13.912" viewBox="0 0 24.295 13.912">
            <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(24.295 13.912) rotate(180)" fill={'#005FFF'}/>
          </svg>
        </Link>
      </More>
    </>),
    seo: {
      title: '',
      description: 'Praca, oferty pracy - Pomarańcza',
      keywords: 'Chcesz u nas pracować? Sprawdź najnowsze oferty pracy w naszej firmie! Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.'
    }
  }
]

const Contact = ({location, contact, colors}) => {
  let url = window.location.pathname.split('/')
  if(url[2] === 'mapa') {
    return <Map />
  }
  return (
    <BG 
      color={ colors.kontakt && colors.kontakt.color}
      overflow="hidden"
    >
      <MenuIcon
        color={ colors.kontakt ? colors.kontakt.color : '' }
      />
      <div
        className="animated fadeInLeft faster" 
        style={{
          animationDelay: `${MIN_ANIMATION + 0.5}s`,
          zIndex: 4,
          position: 'fixed',
        }}
      >
        <Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--dark" exact to={Routes.kontakt.slug}>kontakt</Link>
      </div>
      { list.length > 0 && <Container>
          <TextMobile contact list={list} />
          <TextDesktopInfo contact list={list} spaceBetween={20} />
        </Container>
      }
    </BG>
  )
}

export default connect(mapStateToProps, null)(Contact);
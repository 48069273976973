import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import ReactHtmlParser from 'react-html-parser';


import { Half } from '../../components/grid/half/Half.styled';
import { Container } from '../../components/grid/container/Container.styled';
import MainTitle from '../../components/ui/title/title';
import MenuIcon from '../../components/menu-icon/menu-icon';
import Routes from '../../routes/routes';
import { RodoText } from '../../components/ui/rodo-text/rodo-text.styled'
import { RodoContact, RodoContactHalf, RodoContactFull } from '../../components/ui/rodo-contact/rodo-contact.styled';
import { BG } from '../../components/grid/bg/bg.style';
import { MIN_ANIMATION } from '../../config/config';
import SEO from '../../components/seo/seo';

const mapStateToProps = state => {
  return {rodo: state.rodo};
};

const Rodo = ( {rodo} ) => {
  return (
      
    <BG 
      color="#8D91AB"
    >
      {/* <SEO
        title={rodo.seo.title}
        description={rodo.seo.description}
        keywords={rodo.seo.keywords}
      /> */}
      <MenuIcon 
        color="#8D91AB"
      />
      <div
        className="animated fadeInLeft faster" 
        style={{
          animationDelay: `${MIN_ANIMATION + 0.5}s`,
          zIndex: 4,
          position: 'fixed',
        }}
      >
        <Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--dark" exact to={Routes.kontakt.slug}>kontakt</Link>
      </div>
      <MainTitle 
        absolute={true}
        left='r'
        right='do'
      />
      <Container>
        <Half>
          <RodoText
            className="carousel__item animated fadeInUp faster"
            style={{
              animationDelay: `${MIN_ANIMATION + 0.4}s`
            }}
          >
            { ReactHtmlParser(rodo.content) }
          </RodoText>
        </Half>
        <Half mobileInitial={true} fullHeight={false}>
          <RodoContact>
            <RodoContactHalf
              className="carousel__item animated fadeInUp faster"
              style={{
                animationDelay: `${MIN_ANIMATION + 0.1}s`
              }}
            >
              { ReactHtmlParser(rodo.admin) }
            </RodoContactHalf>
            <RodoContactHalf
              className="carousel__item animated fadeInUp faster"
              style={{
                animationDelay: `${MIN_ANIMATION + 0.2}s`
              }}
            >
              { ReactHtmlParser(rodo.contact) }
            </RodoContactHalf>
            <RodoContactFull
              className="carousel__item animated fadeInUp faster"
              style={{
                animationDelay: `${MIN_ANIMATION + 0.3}s`
              }}
            >
              { ReactHtmlParser(rodo.info) }
            </RodoContactFull>
          </RodoContact>
        </Half>
      </Container>
    </BG>
  )
}

export default connect(mapStateToProps, null)(Rodo);
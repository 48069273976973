import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";
import Player from '../../video/video';

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import Fortuna_comments_01_A from '../../../../assets/case-study/fortuna/comments/01A.png';
import Fortuna_gifs_01 from '../../../../assets/case-study/fortuna/gifs/01.gif';
import Fortuna_gifs_02 from '../../../../assets/case-study/fortuna/gifs/02.gif';
import Fortuna_gifs_03 from '../../../../assets/case-study/fortuna/gifs/03.gif';

import Fortuna_billboard_01_A from '../../../../assets/case-study/fortuna/billboard/01A.png';
import Fortuna_billboard_01_B from '../../../../assets/case-study/fortuna/billboard/01B.png';
import Fortuna_billboard_01_C from '../../../../assets/case-study/fortuna/billboard/01C.png';

import Fortuna_section_3_01_A from '../../../../assets/case-study/fortuna/section_3_animation/01A.png';
import Fortuna_section_3_01_B from '../../../../assets/case-study/fortuna/section_3_animation/01B.png';
import Fortuna_section_3_01_C from '../../../../assets/case-study/fortuna/section_3_animation/01C.png';

import Fortuna_leszek_lichota_01_A from '../../../../assets/case-study/fortuna/leszek_lichota/01A.png';

import Fortuna_komes_01_A from '../../../../assets/case-study/fortuna/komes/01A.png';
import Fortuna_komes_01_B from '../../../../assets/case-study/fortuna/komes/01B.png';
import Fortuna_komes_01_C from '../../../../assets/case-study/fortuna/komes/01C.png';

import Fortuna_miloslaw_01_A from '../../../../assets/case-study/fortuna/miloslaw/01A.png';
import Fortuna_miloslaw_01_B from '../../../../assets/case-study/fortuna/miloslaw/01B.png';
import Fortuna_miloslaw_01_C from '../../../../assets/case-study/fortuna/miloslaw/01C.png';

import { FortunaGlobal } from './fortuna.styled';
import FortunaLogo from '../../carousel/logo/fortuna';
import SEO from '../../../seo/seo';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.browarfortuna.textColor;
  const colorBg = Routes.portfolio.childs.browarfortuna.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#000000' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 5) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO
        title={Routes.portfolio.childs.browarfortuna.name}
        description={Routes.portfolio.childs.browarfortuna.description}
        keywords={Routes.portfolio.childs.browarfortuna.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#000000">
        <TitleCenter color="#000000">
          <span style={{
              color: "#000000"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <FortunaGlobal />
      <RightMenu 
        color={'#F9F8F6'}
        customColor={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'kampania-komes',
            name: 'kampania komes',
          },
          {
            slug: 'kampania-miloslaw',
            name: 'kampania Miłosław',
          },
          {
            slug: 'kampania-fortuna-czarne',
            name: 'kampania Fortuna Czarne',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge synoptis__intro"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster fortuna-logotype"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <FortunaLogo />
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`,
              color: color,
            }}
          >
            Fortuna sprzyja Pomarańczy! razem z Browarem Fortuna uwarzyliśmy kampanie dla 3 marek z ich portfolio: Komesa, Miłosławia i Fortuny. fortuna chyba nam sprzyjała, bo poszło wszystko dobrze. sami zobaczcie.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title
                 style={{
                  color: color,
                  fontSize: '3.02vw',
                 }}
                >
                  Fortuna sprzyja Pomarańczy!
                </Title>
                <Text
                  style={{
                    color: color
                   }}
                >
                  razem z Browarem Fortuna uwarzyliśmy kampanie dla 3 marek z ich portfolio: Komesa, Miłosławia i Fortuny. fortuna chyba nam sprzyjała, bo poszło wszystko dobrze. sami zobaczcie.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts fortuna__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  textColor={color}
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2023}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  textColor={color}
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2024-03-15', 10, 0.5)}
                  max={getProjects('2024-03-15', 10, 0.5)}
                  undertext="projektów"
                />
                <DonutChart
                  textColor={color}
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={320}
                  max={420}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-komes"
          id="kampania-komes"
          className="nutricia-section__pr"
        >
          <TextWrap>
            <SubTitle className="color-fortuna">
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              ”wyjdź poza” z Komesem, jak Leszek Lichota 
            </SubTitle>
            <Text className="color-fortuna">
            ta kampania naprawdę nam wyszła. wyszła poza! bo przekonujemy w niej, że warto wyjść poza to, co już dobrze znamy i spróbować starannie warzonych stylów piwnych, które oferuje Komes.
            </Text>
          </TextWrap>
            <Player
              video={{
                src: 'https://player.vimeo.com/video/910003173?h=c8cb8a9c6d&amp',
              }}
            />
          <TextWrap>
            <Text className="margin-top-extra color-fortuna">
              aby zobrazować istotę idei „Wyjdź poza”, wykorzystaliśmy trik montażowy, sprawiający wrażenie wychodzenia bohatera poza ramy nośnika, zaskakującego odbiorców i symbolicznie ilustrującego nasze przyzwyczajenia.<br/><br/>
            </Text>
            <Text className="color-fortuna">
            twarzą kampanii został Leszek Lichota, który w różnych pozach wychodził poza. w sensie całym ciałem, nie tylko twarzą.<br/>
            kluczem doboru ambasadora była jego autentyczność, pasja i bezpretensjonalność – cechy, które łączą go z charakterem marki. niżej możecie zobaczyć, jak było na planie. 
            </Text>
          </TextWrap>
          <div className="fortuna__leszek_image_1">
            <ImgSection
              baseImg={Fortuna_leszek_lichota_01_A}
            />
          </div>
          <TextWrap>
            <Text className="color-fortuna">
            gdzie mogliście przyłapać Leszka Lichotę na wychodzeniu poza?
            otóż wszędzie. na billboardach, citylightach...
            </Text>
          </TextWrap>
          <div className="fortuna__komes_image_2">
            <ImgSection
              baseImg={Fortuna_billboard_01_C}
              secondImg={Fortuna_billboard_01_B}
              thirdImg={Fortuna_billboard_01_A}
            />
          </div>
          <TextWrap>
            <Text className="text-right color-fortuna">
              ...digitalu i kanałach social media<br/>
              Browaru Fortuna. naszym zdaniem<br/>
              wszystko dobrze wyszło (poza).
            </Text>
          </TextWrap>
          <div className="fortuna__komes_image_1">
            <ImgSection
              baseImg={Fortuna_komes_01_A}
              secondImg={Fortuna_komes_01_B}
              thirdImg={Fortuna_komes_01_C}
            />
          </div>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-miloslaw"
          id="kampania-miloslaw"
          className="animated fadeInUp faster nutricia-section__kampania"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle className="text-right color-fortuna">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              zmień ołpen spejs z Miłosławiem 
            </SubTitle>
            <Text className="text-right color-fortuna">
              plaża pod palmami, niezapomniane przygody i szalone imprezy – tak w skrócie wygląda nasze lato... tyle że nie, bo prawda jest taka, że spędzamy większość ciepłych dni w biurze.<br/><br/>
            </Text>
            <Text className="text-right color-fortuna">
            z tej obserwacji i w ślad za zdefiniowaną w briefie grupą docelową, narodziła się koncepcja kampanii piwa Miłosław Bezalkoholowe: „Miło w ołpen spejsie”. zadedykowaliśmy ją wszystkim tym, którzy tak jak my siedzą latem w czterech ścianach, jakby ironicznie nazywanych „open space’em”.
            </Text>
            <Player
              video={{
                src: 'https://player.vimeo.com/video/922019563?h=5319747924&amp',
              }}
            />
            <Text className="text-right margin-top-extra color-fortuna">
            język kampanii jest inspirowany korpomową i nawiązuje graficznie do znanych pracownikom biurowym aplikacji, prezentacji, komunikatorów czy kalendarzy. narratorem opowieści jest fikcyjny CEO Miło Bezalko sp. z o.o., który przekonuje swoich odbiorców do zmiany „ołpen spejsu” i wyjścia do natury.<br/><br/>
            </Text>
            <Text className="text-right color-fortuna">
            co zczelendżowaliśmy w ramach kampanii? billboardy, citylighty, digital, socjale i aktywację konsumencką.<br/>
            realizacja: Motion Brand<br />
            feedback: wszystko dobrze
            </Text>
          </TextWrap>
          <div className="fortuna__miloslaw_image_1">
            <ImgSection
              baseImg={Fortuna_miloslaw_01_A}
              secondImg={Fortuna_miloslaw_01_B}
              thirdImg={Fortuna_miloslaw_01_C}
            />
          </div>
        </SectionProject>
      </Observer>
      
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-fortuna-czarne"
          id="kampania-fortuna-czarne"
          className="synoptis__section3"
        >
          <TextWrap>
            <SubTitle className="color-fortuna">
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              Czarne na białym, czyli Fortuna Czarne mówi jak jest
            </SubTitle>
            <Text className="color-fortuna">
            tu mogłaby pojawić się twarz uśmiechniętego influencera, który zachęca do zakupu produktu. zamiast tego Fortuna Czarne zwraca się do odbiorcy czarno (a właściwie Czarne) na białym. zarówno w warstwie graficznej: czarny font na białym tle, jak i słownej: prosto z mostu.<br/><br/>
            </Text>
          </TextWrap>
          <div className="synoptis__section3_image_1">
            <ImgSection
              baseImg={Fortuna_section_3_01_A}
              secondImg={Fortuna_section_3_01_B}
              thirdImg={Fortuna_section_3_01_C}
            />
          </div>
          <div className="fortuna__gifs">
            <ImgSection
              baseImg={Fortuna_gifs_03}
            />
          </div>
          <TextWrap>
            <Text className="color-fortuna">
              celem kampanii było zwrócenie uwagi na niestandardowe jak na ciemne piwo cechy: lekkość i orzeźwienie. delikatnie naigrawające się z klisz reklamowych komunikaty, mogliście zobaczyć, przeglądając swoje ulubione strony, social media i gazetki produktowe.<br/><br/>
            </Text>
            <Text className="color-fortuna">
              a komentarze pod postami świadczą o tym, że poszło wszystko dobrze.
            </Text>
          </TextWrap>
          <div className="fortuna__comments_image_1">
            <ImgSection
              baseImg={Fortuna_comments_01_A}
            />
          </div>
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
            style={{
              color: color
            }}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#000">
            <Link to={ Routes.portfolio.childs.synoptis.slug }>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from './../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import Kampania_500_1_A from '../../../../assets/case-study/coccodrillo/500/kampania_500_01_A.png';
import Kampania_500_1_B from '../../../../assets/case-study/coccodrillo/500/kampania_500_01_B.png';

import Print_1_A from '../../../../assets/case-study/coccodrillo/katalog/print_01_A.png';
import Print_1_B from '../../../../assets/case-study/coccodrillo/katalog/print_01_B.png';
import Print_1_C from '../../../../assets/case-study/coccodrillo/katalog/print_01_C.png';

import Print_2_A from '../../../../assets/case-study/coccodrillo/katalog/print_02_A.png';
import Print_2_B from '../../../../assets/case-study/coccodrillo/katalog/print_02_B.png';
import Print_2_C from '../../../../assets/case-study/coccodrillo/katalog/print_02_C.png';

import Outdoor_1_A from '../../../../assets/case-study/coccodrillo/outdoor/outdoor_01_A.png';
import Outdoor_1_B from '../../../../assets/case-study/coccodrillo/outdoor/outdoor_01_B.png';
import Outdoor_1_C from '../../../../assets/case-study/coccodrillo/outdoor/outdoor_01_C.png';

import Outdoor_2_A from '../../../../assets/case-study/coccodrillo/outdoor/outdoor_02_A.png';
import Outdoor_2_B from '../../../../assets/case-study/coccodrillo/outdoor/outdoor_02_B.png';

import Kidsartkids_1_A from '../../../../assets/case-study/coccodrillo/platforma/kidsartkids_01_A.png';
import Kidsartkids_1_B from '../../../../assets/case-study/coccodrillo/platforma/kidsartkids_01_B.png';
import Kidsartkids_1_C from '../../../../assets/case-study/coccodrillo/platforma/kidsartkids_01_C.png';

import Kidsartkids_2_A from '../../../../assets/case-study/coccodrillo/platforma/kidsartkids_02_A.png';
import Kidsartkids_2_B from '../../../../assets/case-study/coccodrillo/platforma/kidsartkids_02_B.png';
import Kidsartkids_2_C from '../../../../assets/case-study/coccodrillo/platforma/kidsartkids_02_C.png';

import Gadzety_kreatywne_1_A from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_01_A.png';
import Gadzety_kreatywne_1_B from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_01_B.png';
import Gadzety_kreatywne_1_C from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_01_C.png';

import Gadzety_kreatywne_2_A from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_02_A.png';
import Gadzety_kreatywne_2_B from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_02_B.png';
import Gadzety_kreatywne_2_C from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_02_C.png';

import Gadzety_kreatywne_3_A from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_03_A.png';
import Gadzety_kreatywne_3_B from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_03_B.png';
import Gadzety_kreatywne_3_C from '../../../../assets/case-study/coccodrillo/gadzety/gadzety_kreatywne_03_C.png';

import Website_01_A from '../../../../assets/case-study/coccodrillo/website/witryna_01_A.png';
import Website_01_B from '../../../../assets/case-study/coccodrillo/website/witryna_01_B.png';
import Website_01_C from '../../../../assets/case-study/coccodrillo/website/witryna_01_C.png';

import Branding_1_A from '../../../../assets/case-study/coccodrillo/branding/branding_01_A.png';
import Branding_1_B from '../../../../assets/case-study/coccodrillo/branding/branding_01_B.png';
import Branding_1_C from '../../../../assets/case-study/coccodrillo/branding/branding_01_C.png';

import PR_1_A from '../../../../assets/case-study/coccodrillo/pr/PR_01_A.png';
import PR_1_B from '../../../../assets/case-study/coccodrillo/pr/PR_01_B.png';
import PR_1_C from '../../../../assets/case-study/coccodrillo/pr/PR_01_C.png';

import Scena_1_A from '../../../../assets/case-study/coccodrillo/scena/01A.png';
import Scena_1_B from '../../../../assets/case-study/coccodrillo/scena/01B.png';
import Scena_1_C from '../../../../assets/case-study/coccodrillo/scena/01C.png';

import { CoccodrilloGlobal } from './coccodrillo.styled';
import Coccodrillo from '../../carousel/logo/coccodrillo';
import SEO from '../../../seo/seo';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.coccodrillo.textColor;
  const colorBg = Routes.portfolio.childs.coccodrillo.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 10) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO
        title={Routes.portfolio.childs.coccodrillo.name}
        description={Routes.portfolio.childs.coccodrillo.description}
        keywords={Routes.portfolio.childs.coccodrillo.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <CoccodrilloGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'Intro',
          },
          {
            slug: 'kampania',
            name: 'Kampania',
          },
          {
            slug: 'kolekcje',
            name: 'kolekcje',
          },
          {
            slug: 'outdoor',
            name: 'outdoor',
          },
          {
            slug: 'akcja',
            name: 'akcja',
          },
          {
            slug: 'gift',
            name: 'gift',
          },
          {
            slug: 'POS',
            name: 'POS',
          },
          {
            slug: 'branding',
            name: 'branding',
          },
          {
            slug: 'PR',
            name: 'PR',
          },
          {
            slug: 'akcja-2',
            name: 'akcja',
          },
          {
            slug: 'akcja-3',
            name: 'akcja',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Coccodrillo/>
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            dziecięce ubrania to poważny temat, ale praca nad nim daje nam mnóstwo radości!
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  dziecięce ubrania to poważny temat
                </Title>
                <Text>
                  ale praca nad nim daje nam mnóstwo radości!
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2012}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2019-12-03', 155, 1)}
                  max={getProjects('2019-12-03', 155, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={298}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  color={color}
                  content="Nasza współpraca to nie tylko zaangażowanie w działania projektowe i produkcyjne, ale również wsparcie strategicznych decyzji marketingowych, strategii komunikacji, realizacja akcji sprzedażowych i wsparcie działań komunikacyjnych i PR. Rdzeniem wieloletniej współpracy były sesje fotograficzne kolekcji kompleksowo realizowane i obsługiwane przez Pomarańczę przez kilka lat."
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania"
          id="kampania"
          className="animated fadeInUp faster altax-section__kampania"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              kampania 500+
            </SubTitle>
            <Text>
              oto jak się robi real time marketing. kampania 360 nawiązująca do wprowadzenia świadczenia społecznego 500+ objęła m.in. POS, outdoor, materiały drukowane i...
            </Text>
          </TextWrap>
          <div className="altax-section__kampania-img1">
            <ImgSection
              baseImg={Kampania_500_1_A}
              thirdImg={Kampania_500_1_B}
            />
          </div>
          <TextWrap>
            <Text>
            ...no i spoty: radiowy i film emitowany na youtube. a fakturę wystawiliśmy na… 500. plus cośtam ;)
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/171107090',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kolekcje"
          id="kolekcje"
          className="altax-section__katalog"
        >
          <TextWrap>
            <SubTitle className="altax-section__katalog-text1">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              katalogi kolekcji i sesje wizerunkowe
            </SubTitle>
            <Text className="altax-section__katalog-text1">
            katalogi kolekcji to bardzo ważne narzędzie dla marki, dlatego tak istotne jest, żeby zrobić wszystko dobrze. 2 razy w roku dla głównych kolekcji wiosna-lato i jesień-zima i mniejsze, okazjonalne. powstało ich kilkanaście. ale żeby powstały, musimy mieć nie tylko kreatywny pomysł, ale też konkretne zaplecze organizacyjne.
            </Text>
          </TextWrap>
          <div className="altax-section__katalog-img1">
            <ImgSection
              baseImg={Print_1_A}
              secondImg={Print_1_B}
              thirdImg={Print_1_C}
            />
          </div>
          <TextWrap className="altax-section__katalog-text2">
            <Text>
              zaplecze konieczne do organizacji castingów i produkcji sesji, na których czasem gościło kilkadziesiąt osób: młodych modeli, ich rodziców (pozdrawiamy Was!) i naszego zespołu. to konkretne zadanie.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/156248869',
            }}
          />
          <div className="altax-section__katalog-img2">
            <ImgSection
              baseImg={Print_2_A}
              secondImg={Print_2_B}
              thirdImg={Print_2_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="outdoor"
          id="outdoor"
          className="altax-section__outdoor"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              kampanie outdoor
            </SubTitle>
            <Text>
              pamiętacie kampanię z tym hipnotyzującym spojrzeniem? ale z którym konkretnie? małego blondynka, zawadiackiej mulatki, czy słodziaka z warkoczem? czy kilkunastu innych? za wszystkie to właśnie my odpowiadaliśmy!
            </Text>
          </TextWrap>
          <div className="altax-section__outdoor-img1">
            <ImgSection
              baseImg={Outdoor_2_A}
              thirdImg={Outdoor_2_B}
            />
          </div>
          <div className="altax-section__outdoor-img2">
            <ImgSection
              baseImg={Outdoor_1_A}
              secondImg={Outdoor_1_B}
              thirdImg={Outdoor_1_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="akcja"
          id="akcja"
          className="altax-section__platforma"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              platforma “kids art kids”
            </SubTitle>
            <Text>
              platforma online angażująca użytkowników i zachęcająca do wspólnej twórczej zabawy rodziców i dzieci. dostarczająca inspiracji i narzędzi. to tutaj organizowaliśmy konkurs “krokodyl ze śmieci zrobiony przez dzieci”, w którym kreatywność rodziców i dzieci całkowicie nas zaskoczyła! a potem kolejny konkurs i kolejny...
            </Text>
          </TextWrap>
          <div className="altax-section__platforma-img1">
            <ImgSection
              baseImg={Kidsartkids_2_A}
              secondImg={Kidsartkids_2_B}
              thirdImg={Kidsartkids_2_C}
            />
          </div>
          <TextWrap>
            <Text>
              ekologiczne torby papierowe, które po zakupach można wykorzystać jako materiał do świetnej zabawy. funcycling ;)
            </Text>
          </TextWrap>
          <div className="altax-section__platforma-img2">
            <ImgSection
              baseImg={Kidsartkids_1_A}
              secondImg={Kidsartkids_1_B}
              thirdImg={Kidsartkids_1_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="gift"
          id="gift"
          className="altax-section__gadzety"
        >
          <TextWrap>
            <SubTitle className="altax-section__gadzety-text1">
              <SubTitleNumber color={color}>5.</SubTitleNumber>
              kreatywne podarunki
            </SubTitle>
            <Text className="altax-section__gadzety-text1">
              dziesiątki unikalnych, barwnie ilustrowanych projektów podarunków dla małych klientów. mama kupiła kurteczkę, a ja dostałem fajną zabawkę. i się pobawię. a co!
            </Text>
          </TextWrap>
          <div className="altax-section__gadzety-img1">
            <ImgSection
              baseImg={Gadzety_kreatywne_1_A}
              secondImg={Gadzety_kreatywne_1_B}
              thirdImg={Gadzety_kreatywne_1_C}
            />
          </div>
          <div className="altax-section__gadzety-img2">
            <ImgSection
              baseImg={Gadzety_kreatywne_2_A}
              secondImg={Gadzety_kreatywne_2_B}
              thirdImg={Gadzety_kreatywne_2_C}
            />
          </div>
          <div className="altax-section__gadzety-img3">
            <ImgSection
              baseImg={Gadzety_kreatywne_3_B}
              secondImg={Gadzety_kreatywne_3_A}
              thirdImg={Gadzety_kreatywne_3_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="POS"
          id="POS"
          className="altax-section__aranzacje"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>6.</SubTitleNumber>
              aranżacje witryn salonów sprzedaży
            </SubTitle>
            <Text>
              ileż to razy już “przebieraliśmy” witryny sklepowe? na każdy sezon i każdą okazję. w salonach sprzedaży małych i dużych. z różnymi projektowymi i technologicznymi trickami. wpisujące się w trendy i dopasowane do aktualnej kolekcji. 
            </Text>
          </TextWrap>
          <div className="altax-section__aranzacje-img1">
            <ImgSection
              baseImg={Website_01_A}
              secondImg={Website_01_B}
              thirdImg={Website_01_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="branding"
          id="branding"
          className="altax-section__branding"
        >
          <TextWrap>
            <SubTitle className="altax-section__branding-text1">
              <SubTitleNumber color={color}>7.</SubTitleNumber>
              solidny branding
            </SubTitle>
            <Text className="altax-section__branding-text1">
              to my wymyśliliśmy brandclaim “kids are kids”. bo dzieci są dziećmi, a na najprostsze pomysły trudno wpaść. a COCCODRILLO by było sobą, musi mieć branding i corporate identity. to też nasz sprawka. 
            </Text>
          </TextWrap>
          <div className="altax-section__branding-img1">
            <ImgSection
              baseImg={Branding_1_A}
              secondImg={Branding_1_B}
              thirdImg={Branding_1_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="PR"
          id="PR"
          className="altax-section__pr"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>8.</SubTitleNumber>
              wysyłki PR pełne zaskoczenia
            </SubTitle>
            <Text>
              otwierasz przesyłkę, kolejną tego dnia. a z pudła wylatują napełnione chwilę wcześniej helem baloniki, unosząc ku górze miniaturowe ubranka z aktualnej kolekcji i pendrive’a z materiałami prasowymi. dobra zmiana w życiu dziennikarza bombardowanego materiałami PR.
            </Text>
          </TextWrap>
          <div className="altax-section__pr-img1">
            <ImgSection
              baseImg={PR_1_B}
              secondImg={PR_1_A}
              thirdImg={PR_1_C}
              layerUp={true}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="akcja-2"
          id="akcja-2"
          className="altax-section__kruk"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>9.</SubTitleNumber>
              Ania Kruk for COCCODRILLO
            </SubTitle>
            <Text>
              na potrzeby wspólnej akcji Ani Kruk i COCCODRILLO wyprodukowaliśmy nie tylko sesję, ale także szereg materiałów drukowanych i projektów online.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/374393017',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="akcja-3"
          id="akcja-3"
          className="altax-section__scena"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>10.</SubTitleNumber>
              Coccodrillo Scena Kulturalna
            </SubTitle>
            <Text className="altax-section__scena-text1">
            koncerty, spektakle, kabarety, kino, zajęcia dla dzieciaków i wiele więcej. po raz kolejny Scena Kulturalna  odwiedziła polskie wybrzeże realizując największy, letni projekt kulturalny w tej części  kraju, organizowany m.in. z telewizją Polsat. do współpracy zaproszona została również marka Coccodrillo, która poprosiła nas o pomoc w promocji tego słonecznego wydarzenia. Scena Kulturalna stała się jedną z sezonowych aktywności, którą w ramach strategicznej współpracy z CDRL SA (właścicielem marki Coccodrillo) opiniowaliśmy oraz rekomendowaliśmy jako działanie efektywne, realizujące kluczowe, komunikacyjne cele.
            </Text>
            <Text>
            w dwa dni, na rozgrzanej wakacyjnym słońcem plaży, udało nam się przeprowadzić zarówno casting wśród dzieciaków, jak i zrealizować nagrania do telewizyjnych spotów promujących markę oraz wydarzenie. spoty dotarły do niemal 1 mln widzów podczas emisji w stacjach Polsat oraz Polsat News.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/524231947',
            }}/>
          <TextWrap>
            <Text>
            dodatkowo stworzyliśmy również serię offline’owych materiałów reklamowych zawierających praktyczne kalendarium po wydarzeniach we wszystkich, pięciu kurortach, w których zatrzymała się Scena Kulturalna.
            </Text>
          </TextWrap>
          <div className="altax-section__scena-img1">
          <ImgSection
              baseImg={Scena_1_A}
              secondImg={Scena_1_B}
              thirdImg={Scena_1_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={ Routes.portfolio.childs.kruszwica.slug }>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import stands_1_a from '../../../../assets/case-study/tesa/stands/01A.png'
import stands_1_b from '../../../../assets/case-study/tesa/stands/01B.png'
import stands_1_c from '../../../../assets/case-study/tesa/stands/01C.png'

import kampania_1_a from '../../../../assets/case-study/tesa/kampania/01A.png'
import kampania_1_b from '../../../../assets/case-study/tesa/kampania/01B.png'
import kampania_1_c from '../../../../assets/case-study/tesa/kampania/01C.png'

import inspiracje_1_a from '../../../../assets/case-study/tesa/inspiracje/01A.png'
import inspiracje_1_b from '../../../../assets/case-study/tesa/inspiracje/01B.png'
import inspiracje_1_c from '../../../../assets/case-study/tesa/inspiracje/01C.png'

import { TesaGlobal } from './tesa.styled';
import SEO from '../../../seo/seo';
import TesaLogo from '../../carousel/logo/tesa';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.tesa.textColor;
  const colorBg = Routes.portfolio.childs.tesa.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round(new Date(date).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 10) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.tesa.name}
        description={Routes.portfolio.childs.tesa.description}
        keywords={Routes.portfolio.childs.tesa.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <TesaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'wideo-produktowe',
            name: 'wideo produktowe',
          },
          {
            slug: 'kampania-allegro',
            name: 'kampania allegro',
          },
          {
            slug: 'standy-produktowe',
            name: 'standy produktowe',
          },
          {
            slug: 'inspiracje',
            name: 'inspiracje',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="vaspar__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <TesaLogo/>
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            wszystko dobrze na każdej szerokości geograficznej gdybyśmy napisali, że nasze projekty są zawsze świeże, wysmakowane i niebanalne, to byłoby to niesamowicie nieskromne. na szczęście napisała to nasza klientka, więc my nie musimy się posuwać do takich zuchwałości.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  wszystko dobrze na każdej szerokości geograficznej 
                </Title>
                <Text>
                  gdybyśmy napisali, że nasze projekty są zawsze świeże, wysmakowane i niebanalne, to byłoby to niesamowicie nieskromne. na szczęście napisała to nasza klientka, więc my nie musimy się posuwać do takich zuchwałości.
                </Text>
              </div>
              
              <div 
                className="tesa__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2016}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2024-10-13', 44, 1)}
                  max={getProjects('2024-10-13', 44, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={280}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  color={color}
                  content="we współpracy z Pomarańcza lubię to, że przygotowane grafiki są zawsze świeże i wysmakowane, propozycje niebanalne i podobają się na każdej szerokości geograficznej. są naprawdę zaangażowani i mam to zaufanie, że projekt zostanie dowieziony na czas"
                  author="Monika Framska"
                  company="Marketing Manager TESA TAPE Sp. Z o.o."
                />
              </div>
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="wideo-produktowe"
          id="wideo-produktowe"
          className="animated fadeInUp faster tesa__section-1"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              wideo produktowe
            </SubTitle>
            <Text>
              można długo rozpisywać się o praktyczności rozwiązań Tesa, ale lepiej po prostu je pokazać. można też długo rozpisywać się o wideo produktowym, które zrobiliśmy na ten temat, ale lepiej, żebyście po prostu je zobaczyli.
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/1007942972?badge=0&amp',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-allegro"
          id="kampania-allegro"
          className="tesa__section"
        >
          <TextWrap>
            <SubTitle className="tesa__right">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              kampania allegro
            </SubTitle>
            <Text className="tesa__right">
              psst, kierowniku, wiedziałeś, że wyszukiwarka Allegro jest częściej wykorzystywana do szukania produktów niż Google? właśnie dlatego skleiliśmy dla TESA kampanię display w serwisie Allegro. zachęcaliśmy w niej, żeby dodać łazience stylu, komfortu i funkcjonalności. w efekcie użytkownicy dodawali produkty do koszyka i finalizowali transakcję. 
            </Text>
          </TextWrap>
          <div className="tesa__kampania__img-1">
            <ImgSection
              baseImg={kampania_1_b}
              thirdImg={kampania_1_a}
              secondImg={kampania_1_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="standy-produktowe"
          id="standy-produktowe"
          className="tesa__section"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              POS
            </SubTitle>
            <Text>
              jeśli chodzi o zwracające uwagę rozwiązania w punktach sprzedaży to stać nas na wiele. na przykład na standy z moskitierą dla produktów chroniących przed owadami czy choinkowe z elementami pomocnymi przy pakowaniu prezentów. zaprezentowaliśmy też swoją teorię ewolucji, ale mamy nadzieję, że nie otrzymamy za nią nagrody Darwina.
            </Text>
          </TextWrap>
          <div className="tesa__stands__img-1">
            <ImgSection
              baseImg={stands_1_c}
              thirdImg={stands_1_b}
              secondImg={stands_1_a}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="inspiracje"
          id="inspiracje"
        >
          <TextWrap>
            <SubTitle className="tesa__right">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              inspiracje
            </SubTitle>
            <Text className="tesa__right">
              czyli jak zostać MacGyverem w swoim domu. wymyśliliśmy dziesiątki kreatywnych pomysłów, jak zrobić coś z niczego i produktów TESA do gazetek inspiracyjnych. ale na samych pomysłach się nie skończyło. wykonaliśmy starannie wszystkie pomysły, sfotografowaliśmy je i umieściliśmy szczegółowe instrukcje, w jaki sposób zrobić je samodzielnie.
            </Text>
          </TextWrap>
          
          <div className="tesa__inspiracje__img-1">
            <ImgSection
              baseImg={inspiracje_1_a}
              thirdImg={inspiracje_1_b}
              secondImg={inspiracje_1_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={Routes.portfolio.childs.brita.slug}>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
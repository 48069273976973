import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from './../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import Viva_Garder_1_new_A1 from '../../../../assets/case-study/altax/viva/01_new/6_a1.png';
import Viva_Garder_1_new_A2 from '../../../../assets/case-study/altax/viva/01_new/6_a2.png';
import Viva_Garder_1_new_B from '../../../../assets/case-study/altax/viva/01_new/6b.png';
import Viva_Garder_1_new_C from '../../../../assets/case-study/altax/viva/01_new/6c.png';


import Viva_Garder_1_A from '../../../../assets/case-study/altax/viva/viva_garden_01_A.png';
import Viva_Garder_1_B from '../../../../assets/case-study/altax/viva/viva_garden_01_B.png';
import Viva_Garder_1_C from '../../../../assets/case-study/altax/viva/viva_garden_01_C.png';

import Viva_Garder_2_A from '../../../../assets/case-study/altax/viva/viva_garden_02_A.png';
import Viva_Garder_2_B from '../../../../assets/case-study/altax/viva/viva_garden_02_B.png';

import www_1_A from '../../../../assets/case-study/altax/www/01A.png';
import www_1_B from '../../../../assets/case-study/altax/www/01B.png';
import www_1_C from '../../../../assets/case-study/altax/www/01C.png';

import pr_1_A from '../../../../assets/case-study/altax/pr/pr_01_A.png';
import pr_1_B from '../../../../assets/case-study/altax/pr/pr_01_B.png';
import pr_1_C from '../../../../assets/case-study/altax/pr/pr_01_C.png';

import pr_2_A from '../../../../assets/case-study/altax/pr/pr_02_A.png';
import pr_2_B from '../../../../assets/case-study/altax/pr/pr_02_B.png';

import opakowania_1_A from '../../../../assets/case-study/altax/opakowania/opakowania_01_A.png';
import opakowania_1_B from '../../../../assets/case-study/altax/opakowania/opakowania_01_B.png';
import opakowania_1_C from '../../../../assets/case-study/altax/opakowania/opakowania_01_C.png';

import POS_1_A from '../../../../assets/case-study/altax/pos/POS_01_A.png';
import POS_1_B from '../../../../assets/case-study/altax/pos/POS_01_B.png';
import POS_1_C from '../../../../assets/case-study/altax/pos/POS_01_C.png';

import POS_2_A from '../../../../assets/case-study/altax/pos/POS_02_A.png';
import POS_2_B from '../../../../assets/case-study/altax/pos/POS_02_B.png';

import POS_3_A from '../../../../assets/case-study/altax/pos/POS_03_A.png';
import POS_3_B from '../../../../assets/case-study/altax/pos/POS_03_B.png';
import POS_3_C from '../../../../assets/case-study/altax/pos/POS_03_C.png';
import { AltaxGlobal } from './altax.styled';
import Altax from '../../carousel/logo/altax';
import SEO from '../../../seo/seo';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.altax.textColor;
  const colorBg = Routes.portfolio.childs.altax.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 5) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.altax.name}
        description={Routes.portfolio.childs.altax.description}
        keywords={Routes.portfolio.childs.altax.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <AltaxGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'Intro',
          },
          {
            slug: 'POS',
            name: 'POS',
          },
          {
            slug: 'showpack',
            name: 'showpack',
          },
          {
            slug: 'packaging',
            name: 'packaging',
          },
          {
            slug: 'POS-2',
            name: 'POS',
          },
          {
            slug: 'web',
            name: 'web',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Altax />
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            trwała i różnorodna, taka jest gama produktów Altaxu. i taka też jest nasza współpraca.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  trwała i różnorodna
                </Title>
                <Text>
                  taka jest gama produktów Altaxu. i taka też jest nasza współpraca.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2012}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2019-12-03', 480, 1)}
                  max={getProjects('2019-12-03', 480, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={270}
                  max={321}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  
                  color={color}
                  content="Wykazaliście maksymalną koncentrację, będąc zawsze gotowymi do działania; od założeń strategicznych w obszarze BTL [...] przez przygotowanie innowacyjnych rozwiązań, na produkcji materiałów kończąc.[...] Współpraca przebiega doskonale, a jej efektem są interesujące i inspiracyjne projekty, które spełniają nasze oczekiwania. [...] Udowodniliście, że można na Was liczyć, a żadne wyzwania nie stanowią dla Was problemu."
                  author="Tomasz Warlich"
                  company="Senior Marketing Manager Sherwin-Williams Poland"
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="POS"
          id="POS"
          className="animated fadeInUp faster altax-section__viva"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              VIVA Garden i VIVA Home 
            </SubTitle>
            <Text>
              linia inspiracyjnych i interaktywnych POS kierowanych do kobiet. plakaty i wobblery, na których - dzięki zastosowaniu druku soczewkowego - zmieniały się obrazy, “malując” elementy wnętrza.
            </Text>
          </TextWrap>
          <div className="altax-section__viva-img1">
            <ImgSection
              baseImg={Viva_Garder_1_new_A1}
              animate={Viva_Garder_1_new_A2}
              secondImg={Viva_Garder_1_new_B}
              thirdImg={Viva_Garder_1_new_C}
            />
          </div>
          <TextWrap>
            <Text className="altax-section__viva-text1">
              albo przypółkowe nośniki z ruchomymi, angażującymi elementami.
            </Text>
          </TextWrap>
          <div className="altax-section__viva-img2">
            <ImgSection
              baseImg={Viva_Garder_2_A}
              thirdImg={Viva_Garder_2_B}
            />
          </div>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="showpack"
          id="showpack"
          className="altax-section__pr"
        >
          <TextWrap>
            <SubTitle className="altax-section__pr-text1">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              opakowania dla sił sprzedaży...
            </SubTitle>
            <Text className="altax-section__pr-text1">
              niskonakładowe showpacki i kreatywne, funkcjonalne opakowania wspierające działania sprzedaży, marketingu i PR’u.
            </Text>
          </TextWrap>
          <div className="altax-section__pr-img1">
            <ImgSection
              baseImg={pr_1_B}
              secondImg={pr_1_A}
              thirdImg={pr_1_C}
              layerUp={true}
            />
          </div>
          <div className="altax-section__pr-img2">
            <ImgSection
              baseImg={pr_2_A}
              thirdImg={pr_2_B}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="packaging"
          id="packaging"
          className="altax-section__opakowania"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              ...i na półkę
            </SubTitle>
            <Text>
              linie produktowe projektowane przez nas od czystej kartki, albo rozwijane na podstawie dostarczonych przez klienta master-layoutów. i lokalizacja linii na rynki wschodnie. i aktualizacja treści. i kto pamięta, co jeszcze…?
            </Text>
          </TextWrap>
          <div className="altax-section__opakowania-img1">
            <ImgSection
              baseImg={opakowania_1_A}
              secondImg={opakowania_1_B}
              thirdImg={opakowania_1_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="POS-2"
          id="POS-2"
          className="altax-section__pos"
        >
          <TextWrap>
            <SubTitle className="altax-section__pos-text1">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              kreatywne koncepty POS
            </SubTitle>
            <Text className="altax-section__pos-text1">
              kreatywne, koncepcyjne nośniki POS budujące markę i wzmacniającą oddziaływanie w punkcie sprzedaży. 
            </Text>
          </TextWrap>
          <div className="altax-section__pos-img1">
            <ImgSection
              baseImg={POS_1_A}
              secondImg={POS_1_B}
              thirdImg={POS_1_C}
            />
          </div>
          <div className="altax-section__pos-img2">
            <ImgSection
              baseImg={POS_3_A}
              secondImg={POS_3_B}
              thirdImg={POS_3_C}
            />
          </div>
          <TextWrap>
            <Text>
              i koncepty pojedynczych nośników, w których tylko “sky is the limit” ;)
            </Text>
          </TextWrap>
          <div>
            <ImgSection
              baseImg={POS_2_A}
              secondImg={POS_2_B}
            />
          </div>
        </SectionProject>
      </Observer>
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="web"
          id="web"
          className="altax-section__www"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>5.</SubTitleNumber>
              strona www
            </SubTitle>
            <Text>
              jakie są mocne strony agencji Pomarańcza? na przykład strona Altax.pl, na której zrobiliśmy remont generalny. nasza ekipa fachurów z największą dbałością potraktowała zarówno o aspekt wizualny, jak i funkcjonalny.  
            </Text>
          </TextWrap>
          <div className="altax-section__www-img1">
            <ImgSection
              baseImg={www_1_A}
              secondImg={www_1_C}
              thirdImg={www_1_B}
            />
          </div>
        </SectionProject>
      </Observer>
      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={ Routes.portfolio.childs.coccodrillo.slug }>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
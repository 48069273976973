import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import logo_1_a from '../../../../assets/case-study/kadefarm/logo/01/01A.png'

import logo_2_a from '../../../../assets/case-study/kadefarm/logo/02/02A.png'
import logo_2_b from '../../../../assets/case-study/kadefarm/logo/02/02B.png'
import logo_2_c from '../../../../assets/case-study/kadefarm/logo/02/02C.png'

import declare_1_a from '../../../../assets/case-study/kadefarm/declare/01/01A.png'
import declare_1_b from '../../../../assets/case-study/kadefarm/declare/01/01B.png'
import declare_1_c from '../../../../assets/case-study/kadefarm/declare/01/01C.png'

import ranisilver_2_a from '../../../../assets/case-study/kadefarm/ranisilver/02/02A.png'

import ranisilver_3_a from '../../../../assets/case-study/kadefarm/ranisilver/03/03A.png'
import ranisilver_3_b from '../../../../assets/case-study/kadefarm/ranisilver/03/03B.png'
import ranisilver_3_c from '../../../../assets/case-study/kadefarm/ranisilver/03/03C.png'

import { AmicaGlobal } from './kadefarm.styled';
import SEO from '../../../seo/seo';
import KadefarmLogo from '../../carousel/logo/kadefarm';
import PasterisanImage from './components/pasterisan-image/Img-section';
import RanisilverImage from './components/ranisilver-01/Img-section';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.kadefarm.textColor;
  const colorBg = Routes.portfolio.childs.kadefarm.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round(new Date(date).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 10) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.kadefarm.name}
        description={Routes.portfolio.childs.kadefarm.description}
        keywords={Routes.portfolio.childs.kadefarm.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <AmicaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'kampania-ranisilver',
            name: 'kampania ranisilver',
          },
          {
            slug: 'kampania-declare',
            name: 'kampania declaré',
          },
          {
            slug: 'kampania-posterisan',
            name: 'kampania posterisan',
          },
          {
            slug: 'loga-opakowania-ulotka',
            name: 'loga + opakowania + ulotka',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="kadefarm__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <KadefarmLogo/>
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            od minimalistycznych projektów po hollywoodzką produkcję Kadefarm to jeden z wielu klientów, którzy po przekazaniu nam jednego projektu, odkryli, że robimy wszystko dobrze i dlatego wrócili z kolejnymi. poznajcie je, w sensie te projekty:
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  od minimalistycznych projektów po hollywoodzką produkcję 
                </Title>
                <Text>
                  Kadefarm to jeden z wielu klientów, którzy po przekazaniu nam jednego projektu, odkryli, że robimy wszystko dobrze i dlatego wrócili z kolejnymi. poznajcie je, w sensie te projekty:
                </Text>
              </div>
              
              <div 
                className="kadefarm__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2012}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2024-06-13', 46, 1)}
                  max={getProjects('2024-06-13', 46, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={320}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-ranisilver"
          id="kampania-ranisilver"
          className="animated fadeInUp faster"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              Ranisilver
            </SubTitle>
            <Text>
              mówi się, że do wesela się zagoi. ale jeśli akurat nie macie w planach żadnego wesela, to lepiej zastosować Ranisilver – wyrób medyczny na rany. no i żeby wiedziało o tym więcej osób, powstała kampania, która jest wręcz do rany przyłóż.<br/><br/> 
              na wszelki wypadek warto wspomnieć, że pożyczonymi z języka codziennego hasłami i prostymi kreacjami zaopiekowaliśmy sociale, digital i prasę. zaangażowaliśmy też influencerów, żeby pokazać, że czas leczy rany jeszcze szybciej, kiedy się mu pomoże.<br/><br/> 
              kampania digital wygenerowała ruch na stronie produktu powyżej naszych oczekiwań, a wszystko to w najgorętszym dla klienta okresie sprzedażowym, czyli wiosenno-letnim, kiedy najczęściej wypadki chodzą po ludziach. 
            </Text>
          </TextWrap>
          <div className="kadefarm__ranisilver__img-1">
            <RanisilverImage />
          </div>
          <div className="kadefarm__ranisilver__img-2">
            <ImgSection
              baseImg={ranisilver_2_a}
            />
          </div>
          <div className="kadefarm__ranisilver__img-3">
            <ImgSection
              baseImg={ranisilver_3_a}
              secondImg={ranisilver_3_c}
              thirdImg={ranisilver_3_b}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-declare"
          id="kampania-declare"
          className="kadefarm__section"
        >
          <TextWrap>
            <SubTitle className="kadefarm__right">
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              Declaré
            </SubTitle>
            <Text className="kadefarm__right">
              zadeklarowaliśmy marce Declaré, że będzie pięknie. i tak też się stało, kiedy zajęliśmy się kanałami społecznościowymi marki stworzonej z myślą o wrażliwej cerze i jej kampanią świadomościową.<br/><br/>
              wysublimowane grafiki, merytoryczne treści wiedzowe o pielęgnacji skóry i przemyślana strategia ich promowania, wpłynęła na to, że poczuliśmy na własnej skórze, jak szybko wskaźniki mogą pójść w górę.
            </Text>
          </TextWrap>
          <div className="kadefarm__declare__img-1">
            <ImgSection
              baseImg={declare_1_a}
              thirdImg={declare_1_b}
              secondImg={declare_1_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-posterisan"
          id="kampania-posterisan"
          className="kadefarm__section"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              Posterisan
            </SubTitle>
            <Text>
            ostatnim, co możemy powiedzieć o komunikacji marki Posterisan jest słowo „sztampa”. bohaterami reklam leku na hemoroidy są rysunkowe pupy, którym produkt pomaga wygodnie się rozsiąść w codziennych sytuacjach. kiedy dostaliśmy brief na kampanię outdoorową, postanowiliśmy zrobić ją z rozmachem i ściągnąć do współpracy zagraniczne gwiazdy.<br/><br/>
            Antonio Kuper, Jessica Pupa, Vaclav Zadek mimo napiętego grafiku, zdecydowali się wystąpić w superprodukcji: „Inwazja z czarnej dziury. Posterisan Ocalenie”. nie musieliśmy nawet kręcić filmu, bo same billboardy stały się viralem. kampania wygenerowała ogrom wzmianek w mediach i pozytywnych komentarzy.
            </Text>
          </TextWrap>
          <div className="kadefarm__posterisan__img-1">
           <PasterisanImage />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="loga-opakowania-ulotka"
          id="loga-opakowania-ulotka"

        >
          <TextWrap>
            <SubTitle className="kadefarm__right">
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              loga + opakowania + ulotka
            </SubTitle>
            <Text className="kadefarm__right">
              spójnie, minimalistycznie, estetycznie. tyle nieskromnie możemy powiedzieć o tym, jak zaprojektowaliśmy loga, opakowania i ulotkę produktów Kadefarm. spójrzcie, jak miło się na nie patrzy. wszystko do siebie pasuje. wszystko ma tu sens. wszystko dobrze.
            </Text>
          </TextWrap>
          <div className="kadefarm__logo__img-1">
            <ImgSection
              baseImg={logo_1_a}
            />
          </div>
          <div className="kadefarm__logo__img-2">
            <ImgSection
              baseImg={logo_2_a}
              thirdImg={logo_2_b}
              secondImg={logo_2_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={Routes.portfolio.childs.brita.slug}>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
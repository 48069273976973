import React from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import MainTitle from '../../components/ui/title/title';
import Routes from '../../routes/routes';
import MenuIcon from '../../components/menu-icon/menu-icon';
import TextMobile from '../../components/text-mobile/text-mobile';
import { BG } from '../../components/grid/bg/bg.style';
import TextDesktopInfo from '../../components/text-desktop-info/text-desktop-info';
import { Container } from '../../components/grid/container/Container.styled';
import { MIN_ANIMATION } from '../../config/config';
import SEO from '../../components/seo/seo';
import Map from '../../pages/map/map';

const mapStateToProps = state => {
  return {colors: state.colors, pomarancza: state.pomarancza};
};



const Pomarancza = ({colors, pomarancza}) => {
  let url = window.location.pathname.split('/')

  return (
    <BG 
      color={ colors.kompetencje ? colors.kompetencje.color : '' }
      overflow="hidden"
    >
      <MenuIcon
        color={ colors.kompetencje ? colors.kompetencje.color : '' }
      />
      <div
        className="animated fadeInLeft faster" 
        style={{
          animationDelay: `${MIN_ANIMATION + 0.5}s`,
          zIndex: 4,
          position: 'fixed',
        }}
      >
        <Link style={{animationDelay: `${MIN_ANIMATION + 0.7}s`}} className="contact-button--animation contact-button contact-button--dark" exact to={Routes.kontakt.slug}>kontakt</Link>
      </div>
      <MainTitle 
        left='c'
        right='&nbsp;robimy?'
      />
      { pomarancza.length > 0 && <Container>
          <TextMobile list={pomarancza} />
          <TextDesktopInfo list={pomarancza} spaceBetween={20} />
        </Container>
      }
    </BG>
  )
}

export default connect(mapStateToProps, null)(Pomarancza);
import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from './nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import opakowania_5_A from '../../../../assets/case-study/nivea/opakowania_5_A.png'
import opakowania_5_B from '../../../../assets/case-study/nivea/opakowania_5_B.png'
import opakowania_5_C from '../../../../assets/case-study/nivea/opakowania_5_C.png'
import custom_publishing_1_A from '../../../../assets/case-study/nivea/custom_publishing_1_A.png'
import custom_publishing_1_B from '../../../../assets/case-study/nivea/custom_publishing_1_B.png'
import custom_publishing_1_C from '../../../../assets/case-study/nivea/custom_publishing_1_C.png'
import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import section_1_A from '../../../../assets/case-study/nivea/section1/opakowania_1_A.png';
import section_1_B from '../../../../assets/case-study/nivea/section1/opakowania_1_B.png';
import section_1_C from '../../../../assets/case-study/nivea/section1/opakowania_1_C.png';
import opakowania_2_A from '../../../../assets/case-study/nivea/section1/opakowania_2_A.png';
import opakowania_2_B from '../../../../assets/case-study/nivea/section1/opakowania_2_B.png';
import opakowania_2_C from '../../../../assets/case-study/nivea/section1/opakowania_2_C.png';
import opakowania_3_A from '../../../../assets/case-study/nivea/section1/opakowania_3_A.png';
import opakowania_3_B from '../../../../assets/case-study/nivea/section1/opakowania_3_B.png';
import opakowania_3_C from '../../../../assets/case-study/nivea/section1/opakowania_3_C.png';
import opakowania_4_A from '../../../../assets/case-study/nivea/section1/opakowania_4_A.png';
import opakowania_4_B from '../../../../assets/case-study/nivea/section1/opakowania_4_B.png';

import Wsparcie_sprzedazy_A from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_1_A.png';
import Wsparcie_sprzedazy_B from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_1_B.png';
import Wsparcie_sprzedazy_C from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_1_C.png';
import Wsparcie_sprzedazy_2_A from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_2_A.png';
import Wsparcie_sprzedazy_2_B from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_2_B.png';
import Wsparcie_sprzedazy_2_C from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_2_C.png';
import Wsparcie_sprzedazy_3_A from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_3_A.png';
import Wsparcie_sprzedazy_3_B from '../../../../assets/case-study/nivea/section2/Wsparcie_sprzedazy_3_B.png';

import Eventy_A from '../../../../assets/case-study/nivea/section4/Eventy_A.png';
import Eventy_B from '../../../../assets/case-study/nivea/section4/Eventy_B.png';
import Eventy_C from '../../../../assets/case-study/nivea/section4/Eventy_C.png';

import Public_relations_1_A from '../../../../assets/case-study/nivea/section5/Public_relations_1_A.png';
import Public_relations_1_B from '../../../../assets/case-study/nivea/section5/Public_relations_1_B.png';
import Public_relations_1_C from '../../../../assets/case-study/nivea/section5/Public_relations_1_C.png';

import Employer_branding_1_A from '../../../../assets/case-study/nivea/work/Employer_branding_1_A.png';
import Employer_branding_1_B from '../../../../assets/case-study/nivea/work/Employer_branding_1_B.png';
import Employer_branding_1_C from '../../../../assets/case-study/nivea/work/Employer_branding_1_C.png';

import Baza_foto_1_A from '../../../../assets/case-study/nivea/but/baza_foto_1_A.png';
import Baza_foto_1_B from '../../../../assets/case-study/nivea/but/baza_foto_1_B.png';
import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import Nivea from '../../carousel/logo/nivea';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';
import SEO from '../../../seo/seo';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.nivea.textColor;
  const colorBg = Routes.portfolio.childs.nivea.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 7) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO
        title={Routes.portfolio.childs.nivea.name}
        description={Routes.portfolio.childs.nivea.description}
        keywords={Routes.portfolio.childs.nivea.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6"
      >
        <TitleCenter color="#F9F8F6"
          className="animated fadeInUp faster"
          style={{
            animationDelay: `0.8s`
          }}
        >
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'Intro',
          },
          {
            slug: 'packaging',
            name: 'packaging',
          },
          {
            slug: 'POS',
            name: 'POS',
          },
          {
            slug: 'akcje',
            name: 'akcje',
          },
          {
            slug: 'eventy',
            name: 'eventy',
          },
          {
            slug: 'PR',
            name: 'PR',
          },
          {
            slug: 'employer',
            name: 'employer',
          },
          {
            slug: 'publishing',
            name: 'publishing',
          },
          {
            slug: 'wideo',
            name: 'Wideo',
          },
          {
            slug: 'foto',
            name: 'foto',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="nivea-section__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Nivea />
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            o pielęgnacji (i) współpracy, czyli niemal 2 dekady pielęgnacji NIVEA - jej wszystkich marek, potrzeb i obszarów. kawał historii w piguł… w puszce kremu NIVEA.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  o pielęgnacji (i) współpracy
                </Title>
                <Text>
                  czyli niemal 2 dekady pielęgnacji NIVEA - jej wszystkich marek, potrzeb i obszarów. kawał historii w piguł… w puszce kremu NIVEA.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2002}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2019-12-03', 4600, 2)}
                  max={getProjects('2019-12-03', 4600, 2)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={311}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  color={color}
                  content="Pomarańcza zna doskonale nasze potrzeby i błyskawicznie na nie reaguje, wykazując się dużą samodzielnością w kompleksowym realizowaniu projektów, które są spójne z naszymi oczekiwaniami. [...] To godny zaufania partner, którego z pełną odpowiedzialnością możemy polecić innym firmom poszukującym solidnej i kompetentnej agencji kreatywnej."
                  author="Heinz-Jürgen Stüting"
                  company="Prezes Zarządu NIVEA Polska"
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="packaging"
          id="packaging"
          className="animated fadeInUp faster"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              opakowania w wersji out of the box
            </SubTitle>
            <Text>
              opakowania estetyczne i skuteczne. akcentujące, co trzeba i wpływające na ostateczną decyzję klienta przy półkach na świecie i w europie... 
            </Text>
          </TextWrap>
          <div
            className="nivea-img__section_1"
          >
            <ImgSection
              baseImg={section_1_A}
              secondImg={section_1_B}
              thirdImg={section_1_C}
            />
          </div>
          
          <TextWrap>
            <Text
              className="nivea-img__opakowania_2_text"
            >
              ...ale także dla bambino - kultowej, tradycyjnej polskiej marki.
            </Text>
          </TextWrap>
          <div
            className="nivea-img__opakowania_2"
          >
            <ImgSection
                baseImg={opakowania_2_C}
                secondImg={opakowania_2_B}
                thirdImg={opakowania_2_A}
              />
          </div>
          <TextWrap>
            <Text
              className="nivea-img__opakowania_3_text"
            >
              dziesiątki prestiżowych showpacków wspierających launche produktów i ekskluzywnych, dedykowanych opakowań wysyłek PR. dla właściwych decyzji w działaniach wizerunkowych i sprzedażowych.
            </Text>
          </TextWrap>
          <div
            className="nivea-img__opakowania_3"
          >
            <ImgSection
              baseImg={opakowania_3_C}
              secondImg={opakowania_3_A}
              thirdImg={opakowania_3_B}
            />
          </div>
          
            <TextWrap>
              <Text
                className="nivea-img__opakowania_4_text"
                alignRight={true}
                paddingLeft="18%"
              >
                zestawy świąteczne na rynek polski - największe handlowe przedsięwzięcie roku NIVEA. nie lada wyzwanie projektowe i techniczne.
              </Text>
            </TextWrap>
            <div
              className="nivea-img__opakowania_4"
            >
              <ImgSection
                baseImg={opakowania_4_B}
                secondImg={opakowania_4_A}
              />
            </div>
            <div
              className="nivea-img__opakowania_5_text"
            >
              <TextWrap>
                <Text
                >
                  tysiące lokalizacji opakowań na rynek polski i kraje bałtyckie, dla wszystkich marek koncernu. artworki, adaptacje master-layoutów i my, na straży standardów.
                </Text>
              </TextWrap>
            </div>
            
          <div
          className="nivea-img__opakowania_5"
          >
            <ImgSection
              baseImg={opakowania_5_A}
              secondImg={opakowania_5_B}
              thirdImg={opakowania_5_C}
            />
          </div>
          
            
        </SectionProject>
      </Observer>
      
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="POS"
          id="POS"
          className="nivea-section__support"
        >
          <TextWrap>
            <SubTitle
              className="nivea-support__title"
              alignRight={true}
            >
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              kampanie i nośniki POS: wyróżniające wsparcie sprzedaży
            </SubTitle>
            
            <Text
              className="nivea-support__text_1"
              alignRight={true}
            >
              kampanie w sieciach handlowych zaprojektowane od początku do końca. key visual i pomysłowe formaty, nie tylko standardowe.
            </Text>
          </TextWrap>
          <div
            className="nivea-support__img"
          >
          <ImgSection
              baseImg={Wsparcie_sprzedazy_A}
              secondImg={Wsparcie_sprzedazy_B}
              thirdImg={Wsparcie_sprzedazy_C}
          />
          </div>
          <TextWrap>
            <Text
              className="nivea-support__text_2"
            >
              koncepcyjne projekty non-standard przeczące prawom fizyki.
            </Text>
          </TextWrap>
          <div
            className="nivea-support__img_2"
          >
            <ImgSection
                baseImg={Wsparcie_sprzedazy_3_A}
                thirdImg={Wsparcie_sprzedazy_3_B}
            />
          </div>
          <TextWrap>
            <Text
              className="nivea-support__text_3"
            >
              a gdy rusza kampania ATL i outdoor - rozwijamy koncept na nośniki BTL, POS i formaty do komunikacji online. spójnie i konsekwentnie.
            </Text>
          </TextWrap>
          <div
            className="nivea-support__img_3"
          >
            <ImgSection
              baseImg={Wsparcie_sprzedazy_2_A}
              secondImg={Wsparcie_sprzedazy_2_B}
              thirdImg={Wsparcie_sprzedazy_2_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="akcje"
          id="akcje"
          className="nivea-section__action"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              akcje konsumenckie: niech się zadzieje!
            </SubTitle>
            <Text>
              wszystko, czego potrzeba, by aktywizować konsumentów. konkursy i loterie - wiadomo, kompleksowo: organizacja, komunikacja, obsługa uczestników, wszystkie te regulaminy i podatki...
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/374393678',
            }}
          />
        </SectionProject>
      </Observer>
      
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="eventy"
          id="eventy"
          className="nivea-section__events"
        >
          <TextWrap>
            <SubTitle
              className="nivea-events__title"
            >
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              wielkie eventy i małe spotkania
            </SubTitle>
            <Text
              className="nivea-events__text"
            >
              koncept, projektowanie, organizacja, produkcja i obsługa wydarzeń. wszystko razem, albo osobno.
            </Text>
          </TextWrap>
          <div
            className="nivea-events__img"
          >
            <ImgSection
              baseImg={Eventy_A}
              secondImg={Eventy_B}
              thirdImg={Eventy_C}
            />
          </div>
          
           <TextWrap>
            <Text 
              className="nivea-events__text_2"
            >
              wyjazdowe konferencje sprzedażowe, spotkania Public Relations, wydarzenia wewnętrzne o charakterze employer branding. dokumentacja foto i wideo.
            </Text>
           </TextWrap>
           <Player
            video={{
              src: 'https://player.vimeo.com/video/374394049',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="PR"
          id="PR"
          className="nivea-section__relations"
        >
          <TextWrap>
            <SubTitle
              className="nivea-relations__title"
            >
              <SubTitleNumber color={color}>5.</SubTitleNumber>
              Public Relations to podstawa
            </SubTitle>
            <Text
              className="nivea-relations__text"
            >
              naszej współpracy, bo to tu się wszystko zaczęło w 2003 roku. i jeden z filarów komunikacji marki. setki publikacji, advertoriali i wydawnictw dla dziennikarzy: opakowań, zaproszeń, konferencji. kreatywnych, unikalnych perełek wykonanych z najstaranniej przygotowanych idei i dobranych materiałów. tak się robi relacje!
            </Text>
          </TextWrap>
          <div
            className="nivea-relations__img"
          >
            <ImgSection
                baseImg={Public_relations_1_A}
                secondImg={Public_relations_1_B}
                thirdImg={Public_relations_1_C}
            />
          </div>
          
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="employer"
          id="employer"
          className="nivea-section__work"
        >
          <TextWrap>
            <SubTitle
              className="nivea-work__title"
            >
              <SubTitleNumber color={color}>6.</SubTitleNumber>
              Employer Branding: do pracowników obecnych i przyszłych
            </SubTitle>
            <Text
              className="nivea-work__text"
            >
              projekty budujące wizerunek pracodawcy w komunikacji marki z rynkiem pracy i z zespołem. nasze projekty zdobiły wnętrza obiektów biurowych i produkcyjnych a akcje wyzwalały emocje. akcje, jak ta - celebrująca sukces rynkowy. 
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/375605809',
            }}
          />
          <TextWrap>
            <Text
              className="nivea-work__text_2"
            >
              a gdy pomysły docierały do niemieckiej centrali, zachwycały i stawały się standardem dla całego koncernu. tak, jak ten:
            </Text>
          </TextWrap>
          <div
            className="nivea-work__img"
          >
            <ImgSection
              baseImg={Employer_branding_1_A}
              secondImg={Employer_branding_1_B}
              thirdImg={Employer_branding_1_C}
            />
          </div>
          
        </SectionProject>
      </Observer>
      
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="publishing"
          id="publishing"
          className="nivea-section__read"
        >
          <TextWrap>
            <SubTitle alignRight={true}>
              <SubTitleNumber color={color}>7.</SubTitleNumber>
              magazyny wewnętrzne: interesujące treści we właściwej formie
            </SubTitle>
            
            <Text
              className="nivea-read__text"
              alignRight={true}
            >
              wewnętrzne magazyny to też nasza sprawka. projektowaliśmy i wydawaliśmy je wszystkie: najpierw kwartalnik “Journal”, a później “Podwórko NIVEA”. za to produkcyjna część koncernu czytała “Teraz My!”.  
            </Text>
          </TextWrap>
          <div
            className="nivea-read__img"
          >
            <ImgSection
              baseImg={custom_publishing_1_A}
              secondImg={custom_publishing_1_B}
              thirdImg={custom_publishing_1_C}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="wideo"
          id="wideo"
          className="nivea-section__video"
        >
          <TextWrap>
            <SubTitle>
            <SubTitleNumber color={color}>8.</SubTitleNumber>
              animacje i filmy: tak to się właśnie kręci…
            </SubTitle>
            <Text
            >
              w portfolio naszej współpracy znajdą się też liczne projekty wideo, animacje i motion design, wykorzystywane we wszystkich przestrzeniach komunikacyjnych: na spotkaniach i konferencjach i w kampaniach online realizowanych przez markę, jak te filmy instruktażowe NIVEA Baby
            </Text>
          </TextWrap>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/161478178',
            }}
          />
        </SectionProject>
      </Observer>
    
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="foto"
          id="foto"
          className="nivea-section__but"
        >
          <TextWrap>
            <SubTitle className="nivea-but__title">
              <SubTitleNumber color={color}>9.</SubTitleNumber>
              packshot dla każdego! 
            </SubTitle>
            <Text
              className="nivea-but__text"
            >
              packshot każdego produktu dostępny dla każdego pracownika i klienta. realizacja tysięcy packshotów produktowych, nadzór nad zgodnością ze standardami oraz publikacja i zarządzanie w stworzonym przez nas w 2008 roku i sukcesywnie rozwijanym środowisku współdzielenia i wymiany materiałów wizualnych online – bazie zdjęć “niveafoto”, która z czasem objęła także kraje bałtyckie oraz inne marki koncernu (np. Eucerin)
            </Text>
          </TextWrap>
          <div
            className="nivea-but__img"
          >
            <ImgSection
              baseImg={Baza_foto_1_A}
              secondImg={Baza_foto_1_B}
            />
          </div>
        </SectionProject>
      </Observer>
      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={ Routes.portfolio.childs.amica.slug }>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
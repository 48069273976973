import React from 'react';

function Altax () {
  return(
    <svg xmlns="http://www.w3.org/2000/svg" width="273.35" height="88.644" viewBox="0 0 273.35 88.644">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_36" data-name="Rectangle 36" width="273.349" height="88.641"/>
        </clipPath>
      </defs>
      <g id="Group_134" data-name="Group 134" transform="translate(0 0.001)">
        <path id="Path_109" data-name="Path 109" d="M35.426,7.179,20.865,47.9h-.194L6.786,7.179H0l17.026,47.1h7.283l17.613-47.1Z" transform="translate(0 14.978)"/>
        <rect id="Rectangle_35" data-name="Rectangle 35" width="6.206" height="69.251" transform="translate(89.719 -0.001)"/>
        <g id="Group_133" data-name="Group 133" transform="translate(0.001 0.002)">
          <g id="Group_132" data-name="Group 132">
            <path id="Path_110" data-name="Path 110" d="M34.382,48.2a31.185,31.185,0,0,0,13.095,2.953c5.916,0,11.129-3.046,11.129-8.407,0-11.175-24.122-9.413-24.122-23.082,0-9.326,8.073-12.845,16.344-12.845a41.754,41.754,0,0,1,12.4,2.136l-.589,5.074a30.982,30.982,0,0,0-11.126-2.216c-6.4,0-10.832,1.846-10.832,7.851,0,8.765,24.708,7.66,24.708,23.082,0,9.971-9.941,13.385-17.517,13.385A40.174,40.174,0,0,1,33.8,53.923Z" transform="translate(70.511 14.225)"/>
            <path id="Path_111" data-name="Path 111" d="M46.447,7.933h6.2l-.392,8.589h.185c1.583-2.589,5.426-9.7,16.051-9.7,14.767,0,20.668,10.345,20.668,24.665,0,13.943-6.888,24.643-20.668,24.643-7.87,0-12.6-3.043-15.65-9.138h-.194V74.416h-6.2Zm21.06,3.876c-11.03,0-14.863,11.082-14.863,19.674s3.833,19.668,14.863,19.668c11.8,0,14.857-10.524,14.857-19.668S79.312,11.809,67.507,11.809" transform="translate(96.897 14.225)"/>
            <path id="Path_112" data-name="Path 112" d="M77.6,18.461a80.468,80.468,0,0,0-.389-10.53H83.4v9.055h.21C85.868,11.718,90.1,6.82,96.5,6.82a19.4,19.4,0,0,1,4.336.559v6.1a17.34,17.34,0,0,0-4.54-.556c-9.833,0-12.49,10.345-12.49,18.838V55.026H77.6Z" transform="translate(161.07 14.227)"/>
            <path id="Path_113" data-name="Path 113" d="M46.123,47.452h-.2c-2.762,5.629-9.749,8.675-15.952,8.675-14.264,0-16.53-9.049-16.53-13.289,0-15.792,17.909-16.526,30.905-16.526h1.179v-2.4c0-8.04-3.046-12.1-11.416-12.1a28.019,28.019,0,0,0-14.764,3.879V10.056A41.86,41.86,0,0,1,34.111,6.819c12.4,0,17.616,5.271,17.616,17.554V45.149a63.366,63.366,0,0,0,.488,9.876H46.123Zm-.6-12.789L45.6,26.6a9.435,9.435,0,0,1-6.888,4.787l-.228.015c-9.237.454-18.255,2.574-18.255,11.249,0,6.182,4.725,8.5,10.431,8.5,13.891,0,14.807-10.823,14.863-16.227Z" transform="translate(28.045 14.225)"/>
            <path id="Path_114" data-name="Path 114" d="M94.351,47.354h-.2c-2.759,5.635-9.749,8.681-15.946,8.681-14.27,0-16.526-9.049-16.526-13.3,0-15.8,17.906-16.533,30.9-16.533h1.179v-2.4c0-8.036-3.049-12.1-11.41-12.1a28,28,0,0,0-14.767,3.879V9.962A41.709,41.709,0,0,1,82.346,6.728c12.4,0,17.616,5.265,17.616,17.542V45.049a63.408,63.408,0,0,0,.488,9.879h-6.1Zm-.589-12.789.077-8.061a9.439,9.439,0,0,1-6.895,4.787l-.235.019c-9.231.454-18.249,2.568-18.249,11.246,0,6.185,4.731,8.5,10.434,8.5,13.891,0,14.811-10.829,14.86-16.236Z" transform="translate(128.678 14.035)"/>
            <path id="Path_115" data-name="Path 115" d="M85.612,11.53A4.568,4.568,0,1,1,90.176,16.1a4.573,4.573,0,0,1-4.564-4.568m8.024,0a3.459,3.459,0,1,0-6.9,0,3.459,3.459,0,1,0,6.9,0m-5.219-2.6h1.938c1.231,0,1.895.429,1.895,1.515A1.287,1.287,0,0,1,90.9,11.826L92.287,14.1H91.3L89.96,11.869h-.583V14.1h-.96Zm.96,2.21h.858c.562,0,1.049-.08,1.049-.768,0-.614-.552-.707-1.046-.707h-.861Z" transform="translate(178.602 14.517)"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Altax;
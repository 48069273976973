import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, BritaGlobal } from './brita.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import Section_1_A from '../../../../assets/case-study/brita/section1/section_1_A.png';
import Section_1_B from '../../../../assets/case-study/brita/section1/section_1_B.png';
import Section_1_C from '../../../../assets/case-study/brita/section1/section_1_C.png';

import Section_1_2_A from '../../../../assets/case-study/brita/section1/section_1_2_A.png';
import Section_1_2_B from '../../../../assets/case-study/brita/section1/section_1_2_B.png';
import Section_1_2_C from '../../../../assets/case-study/brita/section1/section_1_2_C.png';

import Section_2_A from '../../../../assets/case-study/brita/section2/section_2_A.png';
import Section_2_B from '../../../../assets/case-study/brita/section2/section_2_B.png';
import Section_2_C from '../../../../assets/case-study/brita/section2/section_2_C.png';

import Section_2_2_A from '../../../../assets/case-study/brita/section2/section_2_2_A.png';
import Section_2_2_B from '../../../../assets/case-study/brita/section2/section_2_2_B.png';

import Section_3_A from '../../../../assets/case-study/brita/section3/section_3_A.png';
import Section_3_B from '../../../../assets/case-study/brita/section3/section_3_B.png';
import Section_3_C from '../../../../assets/case-study/brita/section3/section_3_C.png';

import Section_4_A from '../../../../assets/case-study/brita/section4/section_4_A.png';
import Section_4_B from '../../../../assets/case-study/brita/section4/section_4_B.png';
import Section_4_C from '../../../../assets/case-study/brita/section4/section_4_C.png';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import Brita from '../../carousel/logo/brita';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';
import SEO from '../../../seo/seo';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.brita.textColor;
  const colorBg = Routes.portfolio.childs.brita.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round((new Date(date)).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 7) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO
        title={Routes.portfolio.childs.brita.name}
        description={Routes.portfolio.childs.brita.description}
        keywords={Routes.portfolio.childs.brita.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6"
      >
        <TitleCenter color="#F9F8F6"
          className="animated fadeInUp faster"
          style={{
            animationDelay: `0.8s`
          }}
        >
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <BritaGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'Intro',
          },
          {
            slug: 'kampanie',
            name: 'kampanie',
          },
          {
            slug: 'POS',
            name: 'POS',
          },
          {
            slug: 'print',
            name: 'print',
          },
          {
            slug: 'copywritting',
            name: 'copywritting',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="brita-section__intro"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Brita />
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            do_wody naszej współpracy liczne! setki, jeśli nie tysiące kreatywnych projektów i nośników do wszystkich kanałów
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  do_wody naszej<br/> współpracy, liczne!
                </Title>
                <Text>
                 setki, jeśli nie tysiące kreatywnych projektów i nośników do wszystkich kanałów
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2010}
                  max={new Date().getFullYear() - 2008}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2022-12-10', 480, 2)}
                  max={getProjects('2022-12-10', 580, 2)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={278}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
              <div
                className="animated fadeInUp faster"
                style={{
                  animationDelay: `1.8s`
                }}
              >
                <BlockQuote 
                  color={color}
                  content="Pomarańcza wykazuje się kreatywnością, zrozumieniem branży oraz wyczuciem marki. Powstały materiały wysokiej wartości estetycznej i merytorycznej. Agencja gwarantuje świetny serwis obsługi klienta oraz rzetelność i terminowość. podsumowuje"
                  author="Marta Kulikowska"
                  company="Dyrektor Marketingu Brita Polska"
                />
              </div>
              
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampanie"
          id="kampanie"
          className="animated fadeInUp faster brita-section__kampanie"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1.</SubTitleNumber>
              materiały kampanijne
            </SubTitle>
            <Text 
              className="brita-kampanie__text-1"  
            >
              kreatywne, dedykowane rozwiązania w punkcie sprzedaży i w digitalu.
            </Text>
          </TextWrap>
          <div
            className="brita-kampanie__img-1"
          >
            <ImgSection
              baseImg={Section_1_C}
              secondImg={Section_1_B}
              thirdImg={Section_1_A}
            />
          </div>
          
          <TextWrap>
            <Text
              className="brita-kampanie__text-2"
            >
              tutaj akurat zaprezentowane w launchu nowości: saturatora SodaOne.
            </Text>
          </TextWrap>
          <div
            className="brita-kampanie__img-2"
          >
            <ImgSection
                baseImg={Section_1_2_C}
                secondImg={Section_1_2_B}
                thirdImg={Section_1_2_A}
              />
          </div>
        </SectionProject>
      </Observer>
      
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="POS"
          id="POS"
          className="brita-section__pos"
        >
          <TextWrap>
            <SubTitle
              className="brita-pos__title"
              alignRight={true}
            >
              <SubTitleNumber color={color}>2.</SubTitleNumber>
              rozwiązania i projekty POS wspomagające trade-marketingowe aktywacje, launche i kampanie...
            </SubTitle>
          </TextWrap>
          <div
            className="brita-pos__img-1"
          >
          <ImgSection
              baseImg={Section_2_C}
              secondImg={Section_2_B}
              thirdImg={Section_2_A}
          />
          </div>
          <div
            className="brita-pos__img-2"
          >
            <ImgSection
                baseImg={Section_2_2_B}
                thirdImg={Section_2_2_A}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="print"
          id="print"
          className="brita-section__print"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3.</SubTitleNumber>
              ...i długa lista zrealizowanych materiałów drukowanych. 
            </SubTitle>
            <Text className="brita-print__text-1">
              produktowych i wizerunkowych, narzędziowych, akcydensowych... wymieniać dalej? ;)
            </Text>
          </TextWrap>
          <div
            className="brita-print__img-1"
          >
            <ImgSection
              baseImg={Section_3_A}
              secondImg={Section_3_C}
              thirdImg={Section_3_B}
            />
          </div>
        </SectionProject>
      </Observer>
      
      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="copywritting"
          id="copywritting"
          className="brita-section__copywritting"
        >
          <TextWrap>
            <SubTitle
              className="brita-copywritting__title"
              alignRight={true}
            >
              <SubTitleNumber color={color}>4.</SubTitleNumber>
              copywriterskie popisy
            </SubTitle>
            <Text
              className="brita-copywritting__text"
              alignRight={true}
            >
              uwielbiamy. i wiemy, że Brita też!<br/>
nie tylko na potrzeby kampanii, ale także szybkie akcje hasełka "na żądanie"
            </Text>
          </TextWrap>
          <div
            className="brita-copywritting__img"
          >
            <ImgSection
              baseImg={Section_4_A}
              secondImg={Section_4_B}
              thirdImg={Section_4_C}
            />
          </div>
        </SectionProject>
      </Observer>
      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={ Routes.portfolio.childs.nivea.slug }>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
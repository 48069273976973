import React from 'react';
import { connect } from "react-redux";
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { API_KEY_GOOGLE_MAPS, POMARANCZA_COORDINATES, INITIAL_COORDINATES_GOOGLE_MAPS } from '../../config/config'
import MainTitle from '../../components/ui/title/title';
import MenuIcon from '../../components/menu-icon/menu-icon';
import MarkerPng from '../../assets/map/marker.png';
import { MapBox } from './map.styled';
import { BG } from '../../components/grid/bg/bg.style';

const mapStateToProps = state => {
  return {colors: state.colors};
};

const Map = ({colors}) => {

  const GOOGLE_MAPS_STYLE = [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": colors.kontakt ? colors.kontakt.color : ''
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": colors.kontakt ? colors.kontakt.color : ''
        },
        {
          "lightness": -50
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "visibility": "off"
        }
      ]
    },
    {
      "featureType": "road",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "lightness": -10
        }
      ]
    },
    {
      "featureType": "transit",
      "elementType": "geometry.fill",
      "stylers": [
        {
          "lightness": -5
        }
      ]
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "lightness": 5
        }
      ]
    }
  ];

  return (
    <BG>
      <MenuIcon/>
      <MainTitle 
        left={false}
        right='&nbsp;tu!'
        delay={0.8}
      />
      <MapBox
        background={colors.kontakt ? colors.kontakt.color : ''}
      >
        <strong>Pomarańcza Sp. z o.o.</strong><br/>ul. Przelot 17<br/>60-408 Poznań
      </MapBox>
      {
      <LoadScript
        id="script-loader"
        googleMapsApiKey={API_KEY_GOOGLE_MAPS}
      >
        <GoogleMap
          id='example-map'
          options={{
            styles: GOOGLE_MAPS_STYLE,
            disableDefaultUI: true,
          }}
          zoom={17}
          center={INITIAL_COORDINATES_GOOGLE_MAPS}
          mapContainerStyle={{
            height: "100vh",
            width: "100vw",
            transformStyle: 'preserve-3d',
            transform: 'perspective(auto) translateZ(-30vw) scale(1.4)',
            perspective: '1000'
          }}
        >
          <Marker
            icon={MarkerPng}
            position={POMARANCZA_COORDINATES}
          />
        </GoogleMap>
      </LoadScript>
      }
    </BG>
  )
}

export default connect(mapStateToProps, null)(Map);
import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import www_1_a from '../../../../assets/case-study/hochland/www/01A.png'
import www_1_b from '../../../../assets/case-study/hochland/www/01B.png'
import www_1_c from '../../../../assets/case-study/hochland/www/01C.png'

import biuro_1_a from '../../../../assets/case-study/hochland/biuro/01A.png'
import biuro_1_b from '../../../../assets/case-study/hochland/biuro/01B.png'
import biuro_1_c from '../../../../assets/case-study/hochland/biuro/01C.png'

import logotyp_1_a from '../../../../assets/case-study/hochland/logotyp/01A.png'
import logotyp_1_b from '../../../../assets/case-study/hochland/logotyp/01B.png'
import logotyp_1_c from '../../../../assets/case-study/hochland/logotyp/01C.png'
import logotyp_1_d from '../../../../assets/case-study/hochland/logotyp/01D.png'

import serwis_1_a from '../../../../assets/case-study/hochland/serwis/01A.png'
import serwis_1_b from '../../../../assets/case-study/hochland/serwis/01B.png'
import serwis_1_c from '../../../../assets/case-study/hochland/serwis/01C.png'

import serownia_1_a from '../../../../assets/case-study/hochland/serownia/01A.png'

import stands_1_a from '../../../../assets/case-study/tesa/stands/01A.png'
import stands_1_b from '../../../../assets/case-study/tesa/stands/01B.png'
import stands_1_c from '../../../../assets/case-study/tesa/stands/01C.png'

import kampania_1_a from '../../../../assets/case-study/tesa/kampania/01A.png'
import kampania_1_b from '../../../../assets/case-study/tesa/kampania/01B.png'
import kampania_1_c from '../../../../assets/case-study/tesa/kampania/01C.png'

import inspiracje_1_a from '../../../../assets/case-study/tesa/inspiracje/01A.png'
import inspiracje_1_b from '../../../../assets/case-study/tesa/inspiracje/01B.png'
import inspiracje_1_c from '../../../../assets/case-study/tesa/inspiracje/01C.png'

import { HochlandGlobal } from './hochland.styled';
import SEO from '../../../seo/seo';
import HochlandLogo from '../../carousel/logo/hochland';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.hochland.textColor;
  const colorBg = Routes.portfolio.childs.hochland.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]


  const Ser = () => {
    return <span style={{color: color}}>ser</span>
  }

  const WhiteDot = () => {
    return <span style={{color: '#fff'}}>.</span>
  }

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round(new Date(date).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 10) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.hochland.name}
        description={Routes.portfolio.childs.hochland.description}
        keywords={Routes.portfolio.childs.hochland.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <HochlandGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'serwis-www',
            name: 'serwis www',
          },
          {
            slug: 'nowe-biuro',
            name: 'nowe biuro',
          },
          {
            slug: 'ulotki',
            name: 'ulotki',
          },
          {
            slug: 'logotypy',
            name: 'logotypy',
          },
          {
            slug: 'serwonia',
            name: 'serownia',
          },
          {
            slug: 'serwis-eventowy',
            name: 'serwis eventowy',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
          className="vaspar__knowledge"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <HochlandLogo/>
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <Ser />deczna współpraca od 2016 włożyliśmy swoje serca w 79 projektów dla marki Hochland. w związku z tym, to co pokażemy niżej, jest tylko wycinkiem (a właściwie plasterkiem) tego, co razem zrobiliśmy. 
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  <Ser />deczna współpraca
                </Title>
                <Text>
                od 2016 włożyliśmy swoje serca w 79 projektów dla marki Hochland. w związku z tym, to co pokażemy niżej, jest tylko wycinkiem (a właściwie plasterkiem) tego, co razem zrobiliśmy. 
                </Text>
              </div>
              
              <div 
                className="hochland__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2016}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2025-03-13', 79, 1)}
                  max={getProjects('2025-03-13', 79, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={180}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="serwis-www"
          id="serwis-www"
          className="animated fadeInUp faster hochland__section-1"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>1<WhiteDot/></SubTitleNumber>
              <Ser />wis www dla grupy Hochland
            </SubTitle>
            <Text>
            jak pokazać ludzką twarz marki? my zdecydowaliśmy się pokazać twarze pracowników, którzy stoją za jej rozwojem i sukcesem w Polsce. zaserwowaliśmy pomysł, potem zrealizowaliśmy sesję zdjęciową, a następnie wdrożyliśmy stronę razem z międzynarodowym zespołem Hochland Group.
            </Text>
          </TextWrap>
          <div className="hochland__www__img-1">
            <ImgSection
              baseImg={www_1_c}
              thirdImg={www_1_b}
              secondImg={www_1_a}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="nowe-biuro"
          id="nowe-biuro"
          className="hochland__section"
        >
          <TextWrap>
            <SubTitle className="hochland__right">
              <SubTitleNumber color={color}>2<WhiteDot/></SubTitleNumber>
              <Ser />wus, nowe biuro!
            </SubTitle>
            <Text className="hochland__right">
            w tym przypadku spotkaliśmy się ze ścianą ze strony klienta. i o to chodziło, bo projekt dotyczył pomysłu i realizacji ściany w strefie wspólnej w nowym biurze Hochland.<br/><br/>
            tak powstał witający gości napis „Just Cheese Yourself” stworzony dzięki pracy zespołowej pracowników. 
            </Text>
          </TextWrap>
          
          <Player
            video={{
              src: 'https://player.vimeo.com/video/1026518750?badge=0&amp',
            }}
          />
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="ulotki"
          id="ulotki"
          className="hochland__section"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>3<WhiteDot/></SubTitleNumber>
              <Ser />ia ulotek
            </SubTitle>
            <Text>
              ulotki dla zabieganych? zrealizowaliśmy takie dla marki Hochland na Poland Business Run w Poznaniu. wypracowaliśmy też ulotki z benefitami pracowniczymi, które nawiązywały do opakowań kultowych serków topionych. a prezentowały się tak:
            </Text>
          </TextWrap>
          <div className="hochland__ulotki__img-1">
            <ImgSection
              baseImg={biuro_1_a}
              thirdImg={biuro_1_b}
              secondImg={biuro_1_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="logotypy"
          id="logotypy"
        >
          <TextWrap>
            <SubTitle className="hochland__right">
              <SubTitleNumber color={color}>4<WhiteDot/></SubTitleNumber>
              <Ser/>io fajne logotypy  
            </SubTitle>
            <Text className="hochland__right">
            zaprojektowaliśmy logotypy dla projektów wewnętrznych: CheeseMasters i Kompas Centrum Rozwoju Pracownika. z nieoficjalnych źródeł wiemy, że podobno każdy projektant graficzny daje je teraz z przyjemnością „większe i na środek”.
            </Text>
          </TextWrap>
          
          <div className="hochland__logotyp__img-1">
            <ImgSection
              baseImg={logotyp_1_a}
              thirdImg={logotyp_1_d}
              secondImg={logotyp_1_c}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="serwonia"
          id="serwonia"
          className="hochland__section"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color={color}>5<WhiteDot/></SubTitleNumber>
              <Ser />ownia w Węgrowie  
            </SubTitle>
            <Text>
            oto nasza produkcja o produkcji sera. jej celem było przedstawienie zakładu produkcyjnego, nowości technologicznych i procesów od odbioru mleka po tworzenie produktów. wspólnie z klientem stworzyliśmy scenariusz, a następnie dwa dni obserwowaliśmy prace i reżyserowaliśmy ujęcia w Węgrowie.
            </Text>
          </TextWrap>
          <div className="hochland__serownia__img-1">
            <ImgSection
              baseImg={serownia_1_a}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="serwis-eventowy"
          id="serwis-eventowy"
        >
          <TextWrap>
            <SubTitle className="hochland__right">
              <SubTitleNumber color={color}>6<WhiteDot/></SubTitleNumber>
              <Ser/>wis eventowy  
            </SubTitle>
            <Text className="hochland__right">
            cieszymy się jak pomarańczki do sera, kiedy organizujemy takie wydarzenia! event strategiczny Hochland to 2 dni, 2 miasta i 800 osób – działo się. <Ser/>io, <Ser/>io!   
            </Text>
          </TextWrap>
          
          <div className="hochland__serwis__img-1">
            <ImgSection
              baseImg={serwis_1_a}
              thirdImg={serwis_1_b}
              secondImg={serwis_1_c}
            />
          </div>
          <Player
            video={{
              src: 'https://player.vimeo.com/video/1027283412?badge=0&amp',
            }}
          />
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={Routes.portfolio.childs.brita.slug}>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;
import React from 'react';

import MenuPosition from "../components/menu/menu-position/items";
import Contact from '../pages/contact/contact';
import Careers from '../pages/careers/careers';
import AboutUs from '../pages/about-us/about-us';
import Production from '../pages/production/production';
import Portfolio from '../pages/portfolio/portfolio';
import Pomarancza from '../pages/pomarancza/pomarancza';
import Client from '../pages/client/client';
import Article from '../pages/article/article';
import Landing from '../pages/landing/landing';
import Home from '../pages/home/home';
import Rodo from '../pages/rodo/rodo';
import PolitykaPrywatnosci from '../pages/polityka-prywatnosci/polityka-prywatnosci';
import Map from '../pages/map/map';
import HomeClients from '../components/portfolio/clients/home/home';
import HomeClients2 from '../components/portfolio/clients/home2/home';

import Kruszwica from '../components/portfolio/clients/kruszwica/kruszwica';
import Nivea from '../components/portfolio/clients/nivea/nivea';
import Brita from '../components/portfolio/clients/brita/brita';
import Amica from '../components/portfolio/clients/amica/amica';
import Altax from '../components/portfolio/clients/altax/altax';
import Coccodrillo from '../components/portfolio/clients/coccodrillo/coccodrillo';
import Nutricia from '../components/portfolio/clients/nutricia/nutricia';
import Synoptis from '../components/portfolio/clients/synoptis/synoptis';
import Fortuna from '../components/portfolio/clients/fortuna/fortuna';
import Kadefarm from '../components/portfolio/clients/kadefarm/kadefarm';
import Valspar from '../components/portfolio/clients/valspar/valspar';
import Tesa from '../components/portfolio/clients/tesa/tesa';
import Hasco from '../components/portfolio/clients/hasco/hasco';
import Hochland from '../components/portfolio/clients/hochland/hochland';

import ContactHome from '../components/contact/contact';
import NiveaLogo from './../components/portfolio/carousel/logo/nivea'
import CoccodrilloLogo from './../components/portfolio/carousel/logo/coccodrillo';
import AltaxLogo from './../components/portfolio/carousel/logo/altax';
import AmicaLogo from './../components/portfolio/carousel/logo/amica';
import KruszwicaLogo from './../components/portfolio/carousel/logo/kruszwica';
import NutriciaLogo from './../components/portfolio/carousel/logo/nutricia';
import BritaLogo from './../components/portfolio/carousel/logo/brita';
import SynoptisLogo from './../components/portfolio/carousel/logo/synoptis';
import FortunaLogo from './../components/portfolio/carousel/logo/fortuna';
import KadefarmLogo from './../components/portfolio/carousel/logo/kadefarm';
import ValsparLogo from './../components/portfolio/carousel/logo/valspar';
import TesaLogo from './../components/portfolio/carousel/logo/tesa';
import HascoLogo from './../components/portfolio/carousel/logo/hasco';
import HochlandLogo from './../components/portfolio/carousel/logo/hochland';

import DotsLogo from './../components/portfolio/carousel/logo/dots';
import NotFound from '../pages/not-found/not-found';

const Routes = {
  home: {
    id: 0,
    flat: 'home',
    name: 'Home',
    description: '',
    keywords: '',
    menuText: {
      left: 'h',
      right: 'me',
    },
    menu: <MenuPosition left="h" center="o" right="me" />,
    slug: '/',
    component: Home,
    exact: true,
    excludedFromMenu: false,
  },
  kompetencje: {
    id: 1,
    flat: 'kompetencje',
    name: 'Kompetencje',
    description: '',
    keywords: '',
    menuText: {
      left: 'k',
      right: 'mpetencje',
    },
    menu: <MenuPosition left="k" center="o" right="mpetencje" />,
    slug: '/kompetencje/',
    component: Pomarancza,
    excludedFromMenu: false,
  },
  portfolio: {
    id: 2,
    flat: 'portfolio',
    name: 'Portfolio',
    description: '',
    keywords: '',
    menuText: {
      left: 'portf',
      right: 'lio',
    },
    menu: <MenuPosition left="portf" center="o" right="lio" />,
    slug: '/portfolio/',
    component: Portfolio,
    excludedFromMenu: false,
    childs: {
      home: {
        id: 0,
        flat: 'home',
        name: 'Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla topowych klientów. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/',
        component: <HomeClients />,
        logo: '',
        exact: true
      },
      altax: {
        id: 1,
        flat: 'altax',
        name: 'Altax - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Altax. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/altax/',
        color: '#E2041D',
        textColor: '#FFE500', // Add text color!
        logo: <AltaxLogo />,
        component: <Altax />
      },
      amica: {
        id: 2,
        flat: 'amica',
        name: 'Amica - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Amica. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/amica/',
        color: '#E41429',
        textColor: '#3C3C3C',
        logo: <AmicaLogo />,
        component: <Amica />
      },
      brita: {
        id: 3,
        flat: 'brita',
        name: 'Brita - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Brita. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/brita/',
        color: '#88D0F1',
        textColor: '#3C3C3C', // Add text color!
        logo: <BritaLogo />,
        component: <Brita />
      },
      browarfortuna: {
        id: 4,
        flat: 'browarfortuna',
        name: 'Browar Fortuna - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Fortuna. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅20 lat doświadczenia ✅Spójne działania online i offline.',
        slug: '/portfolio/browarfortuna/',
        color: '#FFFFFF',
        textColor: '#000000',
        logo: <FortunaLogo />,
        component: <Fortuna />
      },
      coccodrillo: {
        id: 5,
        flat: 'coccodrillo',
        name: 'Coccodrillo - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Coccodrillo. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/coccodrillo/',
        color: '#01AA4F',
        textColor: '#FFE500', // Add text color!
        logo: <CoccodrilloLogo />,
        component: <Coccodrillo />
      },
      hasco: {
        id: 6,
        flat: 'hasco',
        name: 'Haco - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Hasco. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅20 lat doświadczenia ✅Spójne działania online i offline.',
        slug: '/portfolio/hasco/',
        color: '#2E3D56',
        textColor: '#1710E3',
        logo: <HascoLogo />,
        component: <Hasco />
      },
      hochland: {
        id: 7,
        flat: 'hochland',
        name: 'Hochland - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Hochland. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅20 lat doświadczenia ✅Spójne działania online i offline.',
        slug: '/portfolio/hochland/',
        color: '#22338B',
        textColor: '#6CB41E',
        logo: <HochlandLogo />,
        component: <Hochland />
      },
      kadefarm: {
        id: 8,
        flat: 'kadefarm',
        name: 'Kadefarm - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Kadefarm. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅20 lat doświadczenia ✅Spójne działania online i offline.',
        slug: '/portfolio/kadefarm/',
        color: '#3662A6',
        textColor: '#40B1A0',
        logo: <KadefarmLogo />,
        component: <Kadefarm />
      },
      kruszwica: {
        id: 9,
        flat: 'kruszwica',
        name: 'Kruszwica - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Kruszwica. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅20 lat doświadczenia ✅Spójne działania online i offline.',
        slug: '/portfolio/kruszwica/',
        color: '#FFBB00',
        textColor: '#00783B',
        logo: <KruszwicaLogo />,
        component: <Kruszwica />
      },
      nivea: {
        id: 10,
        flat: 'nivea',
        name: 'Nivea - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Nivea. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/nivea/',
        color: '#152D75',
        textColor: '#2C83BD', // Add text color!
        logo: <NiveaLogo />,
        component: <Nivea />
      },
      nutricia: {
        id: 11,
        flat: 'nutricia',
        name: 'Nutricia - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Nutricia. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/nutricia/',
        color: '#4e2683',
        textColor: '#9283BE',
        logo: <NutriciaLogo />,
        component: <Nutricia />
      },
      synoptis: {
        id: 12,
        flat: 'synoptis',
        name: 'Synoptis - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Synoptis. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅ 20 lat doświadczenia ✅ Spójne działania online i offline.',
        slug: '/portfolio/synoptis/',
        color: '#1ED6D6',
        textColor: '#3C3C3C',
        logo: <SynoptisLogo />,
        component: <Synoptis />
      },
      tesa: {
        id: 13,
        flat: 'tesa',
        name: 'Tesa - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Tesa. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅20 lat doświadczenia ✅Spójne działania online i offline.',
        slug: '/portfolio/tesa/',
        color: '#2382B1',
        textColor: '#7DD9FF',
        logo: <TesaLogo />,
        component: <Tesa />
      },
      valspar: {
        id: 14,
        flat: 'valspar',
        name: 'Valspar - Nasze realizacje - Pomarańcza',
        description: 'Przedstawiamy nasze portfolio dla Valspar. Sprawdź naszą ofertę i realizacje. Pomarańcza to ✅20 lat doświadczenia ✅Spójne działania online i offline.',
        slug: '/portfolio/valspar/',
        color: '#123A65',
        textColor: '#3D9BC9',
        logo: <ValsparLogo />,
        component: <Valspar />
      },
      pozostali: {
        id: 15,
        flat: 'pozostali',
        name: 'Klienci - Portfolio - Pomarańcza',
        description: '',
        slug: '/portfolio/pozostali',
        component: <HomeClients2 />,
        logo: <DotsLogo />,
        exact: true
      },
    }
  },
  produkcja: {
    id: 3,
    flat: 'produkcja',
    name: 'Produkcja',
    description: '',
    keywords: '',
    menuText: {
      left: 'pr',
      right: 'dukcja',
    },
    menu: <MenuPosition left="pr" center="o" right="dukcja" />,
    slug: '/produkcja/',
    component: Production,
    excludedFromMenu: false,
  },
  onas: {
    id: 4,
    flat: 'onas',
    name: 'Załoga',
    description: '',
    keywords: '',
    menuText: {
      left: 'zał',
      right: 'ga',
    },
    menu: <MenuPosition left="Zał" center="o" right="ga" />,
    slug: '/o-nas/',
    component: AboutUs,
    excludedFromMenu: false,
  },
  kogoszukamy: {
    id: 5,
    flat: 'kogoszukamy',
    name: 'Kogo szukamy',
    description: '',
    keywords: '',
    menuText: {
      left: 'kog',
      right: '&nbsp;szukamy',
    },
    menu: <MenuPosition left="kog" center="o" right="&nbsp;szukamy" />,
    slug: '/kogo-szukamy/',
    component: Careers,
    excludedFromMenu: false,
  },
  kontakt: {
    id: 6,
    flat: 'kontakt',
    name: 'Kontakt',
    description: '',
    keywords: '',
    menuText: {
      left: 'k',
      right: 'ntakt',
    },
    menu: <MenuPosition left="k" center="o" right="ntakt" />,
    slug: '/kontakt/',
    component: Contact,
    excludedFromMenu: false,
    childs: {
      home: {
        id: 0,
        flat: 'home',
        name: 'Kontakt',
        description: '',
        keywords: '',
        slug: '/kontakt/',
        component: <ContactHome />,
        exact: true
      },
      map: {
        id: 1,
        flat: 'map',
        name: 'Mapa',
        description: '',
        keywords: '',
        slug: '/kontakt/mapa/',
        component: <Map />,
      }
    }
  },
  rodo: {
    id: 8,
    flat: 'rodo',
    name: 'RODO',
    description: '',
    keywords: '',
    slug: '/rodo/',
    component: Rodo,
    excludedFromMenu: true,
  },
  politykaprywatnosci: {
    id: 9,
    flat: 'klauzulainformacyjna',
    name: 'Klauzula informacyjna',
    description: '',
    keywords: '',
    slug: '/klauzula-informacyjna/',
    component: PolitykaPrywatnosci,
    excludedFromMenu: true,
  },
  artykul: {
    id: 10,
    flat: 'artykul',
    name: 'Artykuł',
    slug: '/artykul/:slug',
    component: Article,
    excludedFromMenu: true,
  },
  landing: {
    id: 11,
    flat: 'landing',
    name: 'Landingi',
    slug: '/landing/:slug',
    component: Landing,
    excludedFromMenu: true,
  },
  klient: {
    id: 12,
    flat: 'klient',
    name: 'Klient',
    slug: '/klient/:slug',
    component: Client,
    excludedFromMenu: true,
  },
  notfound: {
    id: 13,
    flat: 'notfound',
    name: 'notfound',
    description: '',
    keywords: '',
    slug: '*',
    component: NotFound,
    excludedFromMenu: true,
  }
}

/**
 * @name setActiveSlide()
 * @description Set active position in menu, 
 * if page not found this method will be set home as a active.
 */
export const setActiveSlide = () => {
  let url = window.location.pathname.split('/', 2)
  url = url.filter(Boolean)[0]
  const getId = Object.keys(Routes).filter(el => Routes[el].slug.replace(/\//g, '') === url && Routes[el].excludedFromMenu === false)[0]
  const id = getId ? Routes[getId].id : 0;
  // console.log('active menu', id)
  return id;
}

/**
 * @name setActiveSlidePortfolio()
 */
export const setActiveSlidePortfolio = () => {
  let url = window.location.pathname.replace(/\//g, '')
  const childs = Routes.portfolio.childs
  const getId = Object.keys(childs).filter(el => childs[el].slug.replace(/\//g, '') === url)[0]
  const id = getId ? childs[getId].id : 0;
  return id ? id : 0;
}

export const getActiveColor = () => {
  let url = window.location.pathname.split('/', 2)
  url = url.filter(Boolean)[0]
  const getId = Object.keys(Routes).filter(el => Routes[el].slug.replace(/\//g, '') === url && Routes[el].excludedFromMenu === false)[0]
  const id = getId ? Routes[getId].flat : Routes['home'].flat;
  return id;
}

export default Routes;
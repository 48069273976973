import { createGlobalStyle } from 'styled-components';


export const HochlandGlobal = createGlobalStyle`

  .hochland {

    &__donuts {
      display: flex;
      justify-content: space-around;
      padding-top: calc(60vh - 3.3vw);
      margin-bottom: 5.729vw;
      @media (max-width: 1023px){
        padding-top: 40px;
      }
    }

    &__knowledge {
      padding-bottom: 4vw;
    }

    &__section-1 {
      margin-top: 5.8vw;
    }

    &__section {
      margin-bottom: 5.8vw;
    }
    &__www {
      &__img-1 {
        margin-top: 7vh;
        margin-left: -10%;
        margin-right: -3%;
      }
    }

    &__ulotki {
      &__img-1 {
        margin-bottom: -6vw;
      }
    }

    &__right {
      text-align: right !important;
    }

    &__logotyp {
      &__img-1 {
        margin-top: 4vw;
        margin-right: -6vw;
        margin-left: 1vw;
      }
    }

    &__serownia {
      &__img-1 {
        margin-top: 4vw;
        margin-right: 5vw;
        margin-left: 4vw;
      }
    }

    &__serwis {
      &__img-1 {
        margin-top: 4vw;
        margin-right: -9vw;
        margin-left: 4vw;
      }
    }
  }
`
import React, { useState, useEffect } from 'react';
import Observer from '@researchgate/react-intersection-observer';
import { Link } from "react-router-dom";

import { TitleWrap, TitleCenter } from '../../../ui/title/title.styled';
import { ProjectWrapperContainer, SectionProject, TextWrap, TopFirst, NiveaGlobal } from '../nivea/nivea.styled';
import { Title } from '../../title/Title.styled';
import { Text } from '../../text/text.styled';
import { SubTitle, SubTitleNumber } from '../../sub-title/sub-title.styled';
import BlockQuote from '../../block-quote/block-quote';
import ImgSection from '../../img-section/Img-section';

import DonutChart from '../../../ui/donat-chart/donat-chart';
import Player from '../../video/video';
import RightMenu from '../../right-menu/right-menu';
import Routes from '../../../../routes/routes';

import { Contact } from '../../button/button.styled';
import { PortfolioMobileLogo, PortfolioMobileText } from '../../portfolio-mobile/portfolio-mobile.styled';
import { BackMobile } from '../../../ui/back-mobile/back-mobile.styled';
import { Next } from '../../../ui/next/next.styled';

import session_1_a from '../../../../assets/case-study/hasco/session/01A.png'
import session_1_b from '../../../../assets/case-study/hasco/session/01B.png'

import kampania_1_a from '../../../../assets/case-study/hasco/kampania/01A.png'
import kampania_1_b from '../../../../assets/case-study/hasco/kampania/01B.png'
import kampania_1_c from '../../../../assets/case-study/hasco/kampania/01C.png'

import social_1_a from '../../../../assets/case-study/hasco/social/01A.png'
import social_1_b from '../../../../assets/case-study/hasco/social/01B.png'
import social_1_c from '../../../../assets/case-study/hasco/social/01C.png'

import kampania_gif_01 from '../../../../assets/case-study/hasco/kampania/gif01.gif'
import kampania_gif_02 from '../../../../assets/case-study/hasco/kampania/gif02.gif'

import album_1_a from '../../../../assets/case-study/hasco/album/01A.png'
import album_1_b from '../../../../assets/case-study/hasco/album/01B.png'
import album_1_c from '../../../../assets/case-study/hasco/album/01C.png'

import { HascoGlobal } from './hasco.styled';
import SEO from '../../../seo/seo';
import HascoLogo from '../../carousel/logo/hasco';

function ProjectWrapper () {
  const [visibility, setVisibility] = useState(false);
  const color = Routes.portfolio.childs.hasco.textColor;
  const colorBg = Routes.portfolio.childs.hasco.color;
  const thresholds = [0, 0.5, 0.10, 0.15, 0.20, 0.25, 0.30, 0.35, 0.40, 0.45, 0.50, 0.55, 0.60, 0.65, 0.70, 0.75, 0.80, 0.85, 0.90, 0.95, 1]

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])
  
  useEffect(() => {
    // document.documentElement.style.setProperty('--cookies-current-color', colorBg );
    document.documentElement.style.setProperty('--portfolio-color-active', '#ffffff' );
    document.documentElement.style.setProperty('--portfolio-color', color );
    document.body.style.background = colorBg
    document.querySelector('.mainClassOfCaseStudy').style.background = colorBg
  })

  function change (event) {
    const top = window.scrollY
    if ( event.target.offsetTop < (top + (window.innerHeight / 2)) 
      && event.target.offsetTop + event.target.offsetHeight > (top + (window.innerHeight / 2) )) {
      setVisibility(event.target.dataset.slug);
      window.history.pushState(null, null, '#'+event.target.dataset.slug);
      // window.location.hash = event.target.dataset.slug;
    }
  }

  function getProjects(date, base, byWeek) {
    const seconds = Math.round((new Date()).getTime() / 1000) - Math.round(new Date(date).getTime() / 1000)
    return (parseInt(seconds / 60 / 60 / 24 / 10) * byWeek) + base
  }

  return (
    <ProjectWrapperContainer>
      <SEO 
        title={Routes.portfolio.childs.hasco.name}
        description={Routes.portfolio.childs.hasco.description}
        keywords={Routes.portfolio.childs.hasco.keywords}
      />
      <BackMobile>
        <Link to={Routes.portfolio.childs.home.slug}><svg xmlns="http://www.w3.org/2000/svg" width="16.376" height="16.377" viewBox="0 0 16.376 16.377">
            <path d="M0,0H10.43V10.43" transform="translate(9.001 15.563) rotate(-135)" fill="none" stroke="#FFFFFF" strokeWidth="2.3"/>
          </svg>
        </Link>
      </BackMobile>
      <TitleWrap hideMobile={true} color="#F9F8F6">
        <TitleCenter color="#F9F8F6">
          <span style={{
              color: "#F9F8F6"
          }} className="icon-signet"></span>
        </TitleCenter>
      </TitleWrap>
      <NiveaGlobal />
      <HascoGlobal />
      <RightMenu 
        color={color}
        active={visibility}
        elements={[
          {
            slug: 'intro',
            name: 'intro',
          },
          {
            slug: 'kampania-tv-i-digital',
            name: 'kampania tv i digital',
          },
          {
            slug: 'social-media',
            name: 'social media',
          },
          {
            slug: 'sesja-zdjeciowa',
            name: 'sesja zdjęciowa',
          },
          {
            slug: 'album-jubileuszowy',
            name: 'album jubileuszowy',
          },
        ]}
      />

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="intro"
          id="intro"
        >
          <PortfolioMobileLogo
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            <HascoLogo/>
          </PortfolioMobileLogo>
          <PortfolioMobileText
            className="animated fadeInUp faster"
            style={{
              animationDelay: `0.8s`
            }}
          >
            na zdrowie, Hasco-Lek! prawdopodobnie większość z nas ma w apteczce produkt z portfolio Hasco-Lek. a my działamy tak, żeby w pierwszym zdaniu zamienić „większość z nas” na „każdy z nas”.
          </PortfolioMobileText>
          <TopFirst>
            <TextWrap>
              <div
                className="nivea-intro__titles animated fadeInUp faster"
                style={{
                  animationDelay: `0.8s`
                }}
              >
                <Title>
                  na zdrowie, Hasco-Lek!
                </Title>
                <Text>
                  prawdopodobnie większość z nas ma w apteczce produkt z portfolio Hasco-Lek. a my działamy tak, żeby w pierwszym zdaniu zamienić „większość z nas” na „każdy z nas”.
                </Text>
              </div>
              
              <div 
                className="nivea-intro__donuts animated fadeInUp faster"
                style={{
                  animationDelay: `1.4s`
                }}
              >
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={new Date().getFullYear() - 2019}
                  max={new Date().getFullYear() - 2000}
                  undertext="lat"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={getProjects('2024-10-13', 17, 1)}
                  max={getProjects('2024-10-13', 17, 1)}
                  undertext="projektów"
                />
                <DonutChart
                  size={117}
                  stroke={color}
                  bgColor={colorBg}
                  text={320}
                  max={360}
                  undertext="stopni"
                  specialChar={'°'}
                />
              </div>
            </TextWrap>
          </TopFirst>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="kampania-tv-i-digital"
          id="kampania-tv-i-digital"
          className="animated fadeInUp faster hasco__section"
          style={{
            animationDelay: `2.8s`
          }}
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color="#fff">1.</SubTitleNumber>
              Juvit - kampania TV i digital
            </SubTitle>
            <Text>
              kiedy witaminy dla dzieci dostępne od 25 lat na rynku zmieniają swoje pozycjonowanie i stają się witaminami dla całej rodziny, to trzeba o tym jakoś zgrabnie zakomunikować. długo dyskutowaliśmy o tym, jak zaintrygować odbiorców. chcieliśmy nawiązać do dziecięcych korzeni marki jednocześnie, informując rodziców, że to produkty również dla nich. tak powstało hasło: „Dbaj o siebie jak o dziecko!”, a za nim wygrany przetarg na social media i następnie na spot telewizyjny. 
            </Text>
          </TextWrap>
           <Player
            className="hasco__tv__player"
            video={{
              src: 'https://player.vimeo.com/video/901428786?h=0a40a273c9&amp',
            }}
          />
          <TextWrap className="hasco__tv__text-1">
            <Text className="hasco__right">
              dlaczego jako dorośli tak często robimy te wszystkie niezdrowe rzeczy, na które nigdy nie pozwalalibyśmy dzieciom? bo czy pozwolilibyśmy trzylatkowi wybrać serial zamiast snu, siedzieć cały wieczór z nosem w telefonie albo unikać badań profilaktycznych?
            </Text>
          </TextWrap>
          <div>
            <ImgSection
              baseImg={kampania_1_a}
              secondImg={kampania_1_b}
              thirdImg={kampania_1_c}
            />
          </div>
          <TextWrap>
            <Text>
              zadaliśmy te pytania wszędzie, gdzie się dało: online i offline, w prasie branżowej, w kampanii display i w telewizji, żeby na końcu zachęcić do dbania o siebie jak o dziecko. bo Juvit od 25 lat dba o zdrowie dzieci, a teraz jest dostępny też dla dorosłych. 
            </Text>
          </TextWrap>
          <div>
            <div className='hasco__tv__section-gifs'>
              <div className="hasco__tv__section-gifs__video">
                <Player
                  className="hasco__tv__section-gifs__player"
                  video={{
                    src: 'https://player.vimeo.com/video/1015630377?h=6f5db04ba5&amp',
                  }}
                />
              </div>
              <div className="hasco__tv__section-gifs__inside">
                 <div className="hasco__tv__gif-2"
                  style={{
                    zIndex: 10,
                    position: 'relative',
                  }}
                >
                  <ImgSection
                    baseImg={kampania_gif_01}
                  />
                </div>
                <div className="hasco__tv__gif-2"
                  style={{
                    zIndex: 10,
                    position: 'relative',
                  }}
                >
                  <ImgSection
                    baseImg={kampania_gif_02}
                  />
                </div>
              </div>
            </div>
          </div>
          <TextWrap>
            <Text>
              ale tak jak już wspomnieliśmy, wszystko zaczęło się od przetargu na social media i o tym w następnej sekcji.
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="social-media"
          id="social-media"
          className="hasco__section"
        >
          <TextWrap>
            <SubTitle className="hasco__right">
              <SubTitleNumber color="#fff">2.</SubTitleNumber>
              Juvit - social media
            </SubTitle>
            <Text className="hasco__right">
            poza działaniami skupionymi wokół kampanii telewizyjnej, prowadzimy regularną komunikację marki Juvit w mediach społecznościowych. bo dbać o siebie jak o dziecko warto cały czas! 
            </Text>
          </TextWrap>
          {/* <Player
            video={{
              src: 'https://player.vimeo.com/video/984783916?h=3610d76bba&amp',
            }}
          /> */}
           <div className="hasco__social__img-1">
            <ImgSection
              baseImg={social_1_a}
              secondImg={social_1_b}
              thirdImg={social_1_c}
            />
          </div>
          <TextWrap>
            <Text className="hasco__right">
              o co chodzi z lewoskrętną witaminą c? czy obróbka termiczna niszczy witaminy w warzywach? jak radzić sobie ze stresem? te i szereg innych edukacyjnych tematów poruszamy na Facebooku i Instagramie marki. nawiązaliśmy również współpracę z psycholożką, której treści są skupione wokół troski o siebie.
            </Text>
          </TextWrap>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="sesja-zdjeciowa"
          id="sesja-zdjeciowa"
          className="hasco__section"
        >
          <TextWrap>
            <SubTitle>
              <SubTitleNumber color="#fff">3.</SubTitleNumber>
              skąd się biorą dzieci... <br/>na opakowaniach?
            </SubTitle>
            <Text>
              biorą się z sesji zdjęciowych, takich jak ta. zorganizowanych i wyprodukowanych przez nas. gdy więc sięgasz do apteczki po Ibum Forte Pure, to widzisz naszą foteczkę.
            </Text>
          </TextWrap>
          <div className="hasco__session__img-1">
            <ImgSection
              baseImg={session_1_a}
              secondImg={session_1_b}
            />
          </div>
        </SectionProject>
      </Observer>

      <Observer
        onChange={change}
        threshold={thresholds}
      >
        <SectionProject
          data-slug="album-jubileuszowy"
          id="album-jubileuszowy"
        >
          <TextWrap>
            <SubTitle className="hasco__right">
              <SubTitleNumber color="#fff">4.</SubTitleNumber>
              tu jest nie „jakby”, <br/>a naprawdę luksusowo 
            </SubTitle>
            <Text className="hasco__right">
              niemal 170 stron treści o jednym z największych polskich producentów farmaceutycznych zasługuje na „efekt wow”. i nie chodzi tu o „większe logo”. album na 35-lecie grupy Hasco-Lek wykonaliśmy z największą starannością. tłoczenia na okładce, surowce z najwyższej półki i ekskluzywny minimalizm idealnie pasują do bogatego portfolio produktowego. złoto-granatowe, a skromne.
            </Text>
          </TextWrap>
          
          <div className="hasco__album__img-1">
            <ImgSection
              baseImg={album_1_a}
              thirdImg={album_1_b}
              secondImg={album_1_c}
            />
          </div>
          {/* <div className="hasco__castorama__img-2"
            style={{
              zIndex: 10,
              position: 'relative',
            }}
          >
            <ImgSection
              baseImg={castorama_gif}
            />
          </div>
          <div className="hasco__castorama__img-2">
            <ImgSection
              baseImg={castorama_2_a}
              thirdImg={castorama_2_c}
              secondImg={castorama_2_b}
            />
          </div> */}
        </SectionProject>
      </Observer>

      <SectionProject>
        <TextWrap>
          <Contact
            color={color}
          >
            spodobało Ci się?
            <a href="mailto:hello@pomarancza.pl">napisz do nas! 
            <svg xmlns="http://www.w3.org/2000/svg" width="66.333" height="37.985" viewBox="0 0 66.333 37.985">
              <path id="Path_94" data-name="Path 94" d="M-141.871,188.5h53.626l-9.146-9.984,5.375-5.168,16.479,18.993L-92.016,211.33l-5.375-5.168,9.146-9.984h-53.626Z" transform="translate(141.871 -173.345)" fill={color}/>
            </svg>
            </a>
          </Contact>
          <Next color="#fff">
            <Link to={Routes.portfolio.childs.brita.slug}>
              następny klient<br/>
              <svg xmlns="http://www.w3.org/2000/svg" width="13.912" height="24.295" viewBox="0 0 13.912 24.295">
                <path d="M24.3,5.549H4.654L8,1.893,6.035,0,0,6.956l6.035,6.956L8,12.019,4.654,8.363H24.3Z" transform="translate(0 24.295) rotate(-90)" fill="#fff"/>
              </svg>
            </Link>
          </Next>
        </TextWrap>
      </SectionProject>
    </ProjectWrapperContainer>
  )
}

export default ProjectWrapper;